<template>
  <div >
    <md-table v-model="users" :table-header-color="tableHeaderColor">
      <md-table-row v-if="bankCode='VCB'" slot="md-table-row" slot-scope="{ item }"
      @click.native="desClick(item.Description)"
      >
        <md-table-cell md-label="Thời gian">{{ item.TransactionDate }}</md-table-cell>
        <md-table-cell md-label="Số Tiền">{{ item.Amount }}</md-table-cell>
        <md-table-cell @click.native="desClick(item.Description)" style="color: red; font-weight: bold;" md-label="Mã sai">
          <input type="text" :value="item.Description">
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>

export default {
  name: "simple-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: [],
      users: [
      ],
      bankCode: '',
      isLoading: false,
      fullPage: true
    };
  },
  async created() {
    this.$eventBus.$on('update:atm', this.handelATM)
    this.bankCode = localStorage.bankCode;
  },
  methods: {
    handelATM(e) {
      this.users = e;
    },
    desClick(des) {
      this.$eventBus.$emit('update:magdsai2', des)
    }
  },
  async mounted() {
    // await this.$ServerWebService.laylichsunapxuatmmomo()
    // .then(r => {
    //   console.log(r.data.data);
    //   // this.users = r.data.data;
    // })
    // .catch(e=> {
    // })
    
  }
};
</script>
