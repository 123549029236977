<template>
    <div>
        <md-table v-model="users" :table-header-color="tableHeaderColor">
            <md-table-row slot="md-table-row" slot-scope="{ item }"
            @click="desClick(item.tranId)"
            >
                <md-table-cell md-label="Thời gian">{{ item.ackTime }}</md-table-cell>
                <md-table-cell md-label="Số Tiền">{{ parseInt(item.amount).toLocaleString('en-US') }}</md-table-cell>
                <md-table-cell style="color: red; font-weight: bold;" md-label="Mã Sai (***)">{{ item.tranId }}</md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
export default {
    name: "momo-table",
    props: {
        tableHeaderColor: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            selected: [],
            users: [
            ],
        };
    },
    async created() {
    this.$eventBus.$on('update:momo', this.handelmomo)
    },
    methods: {
        handelmomo(e) {
            this.users = e;
        },
        desClick(des) {
            console.log(des);
            this.$eventBus.$emit('update:magdsai1', des)
        }
    },
    async mounted() {
        // await this.$ServerWebService.laylichsunapxuatmmomo()
        // .then(r => {
        //   console.log(r.data.data);
        //   // this.users = r.data.data;
        // })
        // .catch(e=> {
        // })

    }
};
</script>
