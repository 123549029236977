<template>
  <div class="content">
    <!-- <div class="md-layout">
      
    </div> -->

    <div class="mb-3">
      <label for="thuoctinhselect" class="form-label">Thuộc tính</label>
      <select class="form-select" aria-label="Default select example" name="thuoctinhselect" nLoadDa id="nLoadDa">
        <option selected disabled>Chọn Loại</option>
        <option value="vitality_v">Thể chất +{1}</option>
        <option value="dexterity_v">Nhanh nhẹn +{1}</option>
        <option value="strength_v">Sức mạnh +{1}</option>
        <option value="energy_v">Linh hoạt +{1}</option>
        <option value="lifemax_v">Sinh lực +{1}</option>
        <option value="lifemax_p">Sinh lực cơ bản +{1}%</option>
        <option value="lifecurmax_p">Sinh lực +{1}%</option>
        <option value="recover_life_v">Hồi phục sinh lực +{1}</option>
        <option value="lifereplenish_p">Hiệu suất hồi phục +{1}%</option>
        <option value="runspeed_p">Tốc độ di chuyển +{1}</option>
        <option value="runspeed_v">Tốc độ di chuyển +{1}</option>
        <option value="physical_damage_v">Sát thương thuộc tính +{1}</option>
        <option value="physics_potentialdamage_p">Tấn công cơ bản +{1}%</option>
        <option value="basic_damage_v">Tấn công +{1}</option>
        <option value="physical_metaldamage_v">Sát thương hệ Kim +{1}</option>
        <option value="physical_wooddamage_v">Sát thương hệ Mộc +{1}</option>
        <option value="physical_waterdamage_v">Sát thương hệ Thủy +{1}</option>
        <option value="physical_firedamage_v">Sát thương hệ Hỏa +{1}</option>
        <option value="physical_earthdamage_v">Sát thương hệ Thổ +{1}</option>
        <option value="metal_resist_v">Kháng Kim +{1}</option>
        <option value="wood_resist_v">Kháng Mộc +{1}</option>
        <option value="water_resist_v">Kháng Thủy +{1}</option>
        <option value="fire_resist_v">Kháng Hỏa +{1}</option>
        <option value="earth_resist_v">Kháng Thổ +{1}</option>
        <option value="all_series_resist_v">Kháng tất cả +{1}</option>
        <option value="all_series_resist_p">Kháng tất cả cơ bản +{1}%</option>
        <option value="ignore_metal_resist_v">Bỏ qua kháng Kim +{2}</option>
        <option value="ignore_wood_resist_v">Bỏ qua kháng Mộc +{2}</option>
        <option value="ignore_water_resist_v">Bỏ qua kháng Thủy +{2}</option>
        <option value="ignore_fire_resist_v">Bỏ qua kháng Hỏa +{2}</option>
        <option value="ignore_earth_resist_v">Bỏ qua kháng Thổ +{2}</option>
        <option value="ignore_all_resist_v">Bỏ qua kháng tất cả +{2}</option>
        <option value="ignore_all_resist">Bỏ qua kháng tính cuối +{2}%</option>
        <option value="attackspeed_v">Tốc đánh +{1}</option>
        <option value="attackrate_v">Chính xác +{1}</option>
        <option value="attackrate_p">Chính xác cơ bản +{1}%</option>
        <option value="defense_v">Né tránh +{1}</option>
        <option value="defense_p">Né tránh cơ bản +{1}%</option>
        <option value="miss_alldmg_v">Né tránh hoàn toàn +{1}</option>
        <option value="ignore_defense_v">Bỏ qua né tránh +{1}</option>
        <option value="ignore_defense_p">Bỏ qua né tránh cuối +{1}%</option>
        <option value="ignore_defense_vp">Bỏ qua né tránh cơ bản +{1}%</option>
        <option value="ignore_all_resist_vp">Bỏ qua kháng tất cả cơ bản +{1}%</option>
        <option value="ignore_deadlystrike_vp">Bỏ qua tỉ lệ bạo kích cơ bản +{1}%</option>
        <option value="deadlystrike_v">Tỉ lệ bạo kích +{1}</option>
        <option value="deadlystrike_p">Tỉ lệ bạo kích cơ bản +{1}%</option>
        <option value="deadlystrike_damage_p">Sát thương bạo kích +{1}%</option>
        <option value="weaken_deadlystrike_v">Tỉ lệ kháng bạo kích +{1}</option>
        <option value="weaken_deadlystrike_damage_p">Miễn sát thương bạo kích +{1}%</option>
        <option value="state_hurt_attackrate">Tỉ lệ Bị Thương +{1}</option>
        <option value="state_stun_attackrate">Tỉ lệ Choáng +{1}</option>
        <option value="state_slowall_attackrate">Tỉ lệ Trì Hoãn +{1}</option>
        <option value="state_zhican_attackrate">Tỉ lệ Tàn Phế +{1}</option>
        <option value="state_palsy_attackrate">Tỉ lệ Tê Liệt +{1}</option>
        <option value="state_hurt_attacktime">Thời gian Bị Thương +{1}</option>
        <option value="state_stun_attacktime">Thời gian Choáng +{1}</option>
        <option value="state_slowall_attacktime">Thời gian Trì Hoãn +{1}</option>
        <option value="state_zhican_attacktime">Thời gian Tàn Phế +{1}</option>
        <option value="state_palsy_attacktime">Thời gian Tê Liệt +{1}</option>
        <option value="add_seriesstate_rate_v">Tỉ lệ thuộc tính +{1}</option>
        <option value="add_seriesstate_time_v">Thời gian thuộc tính +{1}</option>
        <option value="add_allspecialstate_rate_v">Tỉ lệ bất lợi +{1}</option>
        <option value="add_allspecialstate_time_v">Thời gian bất lợi +{1}</option>
        <option value="state_hurt_resistrate">Tỉ lệ kháng Bị Thương +{1}</option>
        <option value="state_stun_resistrate">Tỉ lệ kháng Choáng +{1}</option>
        <option value="state_slowall_resistrate">Tỉ lệ kháng Trì Hoãn +{1}</option>
        <option value="state_zhican_resistrate">Tỉ lệ kháng Tàn Phế +{1}</option>
        <option value="state_palsy_resistrate">Tỉ lệ kháng Tê Liệt +{1}</option>
        <option value="state_hurt_resisttime">Thời gian kháng Bị Thương +{1}</option>
        <option value="state_stun_resisttime">Thời gian kháng Choáng +{1}</option>
        <option value="state_slowall_resisttime">Thời gian kháng Trì Hoãn +{1}</option>
        <option value="state_zhican_resisttime">Thời gian kháng Tàn Phế +{1}</option>
        <option value="state_palsy_resisttime">Thời gian kháng Tê Liệt +{1}</option>
        <option value="resist_allseriesstate_rate_v">Tỉ lệ kháng thuộc tính +{1}</option>
        <option value="resist_allseriesstate_time_v">Thời gian kháng thuộc tính +{1}</option>
        <option value="resist_allspecialstate_rate_v">Tỉ lệ kháng bất lợi +{1}</option>
        <option value="resist_allspecialstate_time_v">Thời gian kháng bất lợi +{1}</option>
        <option value="ignore_series_state">Giải trừ và miễn dịch thuộc tính</option>
        <option value="ignore_abnor_state">Giải trừ và miễn dịch bất lợi</option>
        <option value="steallife_p">Hút sinh lực +{1}%</option>
        <option value="steallife_v">Hút sinh lực +{1}</option>
        <option value="steallife_resist_v">Kháng hút sinh lực +{1}</option>
        <option value="steallife_resist_p">Kháng hút sinh lực +{1}%</option>
        <option value="meleedamagereturn_p">Phản đòn cận chiến +{1}%</option>
        <option value="rangedamagereturn_p">Phản đòn tầm xa +{1}%</option>
        <option value="meleedamagereturn_v">Phản đòn cận chiến +{1}</option>
        <option value="rangedamagereturn_v">Phản đòn tầm xa +{1}</option>
        <option value="return_resist_p">Phản đòn miễn thương +{1}%</option>
        <option value="series_metaldamagereturn_v">Phản đòn sát thương hệ Kim +{1}</option>
        <option value="series_wooddamagereturn_v">Phản đòn sát thương hệ Mộc +{1}</option>
        <option value="series_waterdamagereturn_v">Phản đòn sát thương hệ Thủy +{1}</option>
        <option value="series_firedamagereturn_v">Phản đòn sát thương hệ Hỏa +{1}</option>
        <option value="series_earthdamagereturn_v">Phản đòn sát thương hệ Thổ +{1}</option>
        <option value="fightpower_v1">Lực chiến +{1}</option>
        <option value="series_enhance_v">Ngũ hành cường hóa +{1}</option>
        <option value="series_abate_v">Ngũ hành nhược hóa +{1}</option>
        <option value="add_allfaction">Cấp kỹ năng môn phái +{1}</option>
        <option value="enhance_final_damage_p">Tăng sát thương cuối +{1}%</option>
        <option value="reduce_final_damage_p">Triệt tiêu sát thương cuối +{1}%</option>
        <option value="melee_dmg_p">Kháng sát thương cận chiến +{1}%</option>
        <option value="remote_dmg_p">Kháng sát thương tầm xa +{1}%</option>
        <option value="damage4npc_p">Sát thương quái +{1}%</option>
        <option value="damage4player_p">Sát thương lên người chơi +{1}%</option>
        <option value="playerdmg_npc_p">Ngăn sát thương nhân vật +{1}%</option>

      </select>
    </div>



    <md-card-content>
      <div class="md-layout">


<div class="md-layout-item md-small-size-100 md-size-33">
  <md-field>
    <label>Chọn máy chủ</label>

    <md-select @md-selected="onProvinceItemSelected" v-model="serverCode" name="gmtoolformserver_id"
      id="gmtoolformserver_id">
      <md-option v-for="(item, idx) in serverList" v-bind:key="idx" :value="item.ServerID">{{ item.ServerName
      }}</md-option>
    </md-select>

  </md-field>
</div>

</div>
            <md-table v-model="rankList" table-header-color="white">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="ID Player">{{ item.dwID }}</md-table-cell>
                    <md-table-cell md-label="Rank">{{ item.Rank }}</md-table-cell>
                    <md-table-cell md-label="Tên">{{ item.Name }}</md-table-cell>
                    <md-table-cell md-label="Lực Chiến">{{ item.Number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</md-table-cell>
                </md-table-row>

            </md-table>

          </md-card-content>

  </div>
</template>

<style>
.manager_server {
  position: fixed;
}
</style>

<script>
import {
  // StatsCard,
  // ChartCard,
  // NavTabsCard,
  NavTabsTable,
  OrderedTable,
} from "@/components";

export default {
  components: {
    // StatsCard,
    // ChartCard,
    // NavTabsCard,
    NavTabsTable,
    OrderedTable,
  },
  props: {
    dataBackgroundColor: {
      type: String,
      default: "green",
    },
  },
  async mounted() {
    if(this.$route.query.token) {
          localStorage.token = this.$route.query.token;
          localStorage.username = this.$route.query.Account;
          localStorage.defaultPortWeb = this.adminlist.find(i => i.Account == localStorage.username).port
          localStorage.bankCode = this.adminlist.find(i => i.Account == localStorage.username).bankCode
          localStorage.portGame = this.adminlist.find(i => i.Account == localStorage.username).portGame
          localStorage.nType = this.adminlist.find(i => i.Account == localStorage.username).nType
                  await this.$ServerWebService.loadServerGame()
      .then(async (res) => {
        this.serverList = res;
      })

          // await this.$ServerWebService.loadingServerList()

          //     .then((r) => {
          //       console.log(r);
          //       this.serverList = r.data;
          //         this.$eventBus.$emit(`update:serverlist`, r.data);
          //     })
          //     .catch((e) => {
          //     })

      //         await this.$ServerWebService.loadServerGame()
      // .then(async (res) => {
      //   this.serverList = res;
      // })
      // .catch((eer) => {
      //   this.$notify(
      //     {
      //       message: eer.response.data.message,
      //       icon: 'add_alert',
      //       horizontalAlign: 'center',
      //       verticalAlign: 'top',
      //       type: 'danger'
      //     })
      // })

          this.$router.push('/')
          this.$eventBus.$emit(`update:login`)
    } else {
      await this.$ServerWebService.loadServerGame()
      .then(async (res) => {
        this.serverList = res;
      })
      .catch((eer) => {
        this.$notify(
          {
            message: eer.response.data.message,
            icon: 'add_alert',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          })
      })
    }



    
  },

  data() {
    return {
      serverList: [],
      serverCode: 0,
      rankList: [],
      adminlist: this.$admin_list,
    };
  },
  methods: {
    async onProvinceItemSelected() {
      // console.log(this.itemSelected);
      await this.$ServerWebService.getRankBoard(this.serverCode)
      .then(async (res) => {
        this.rankList = res;
      })
      .catch((eer) => {
        this.$notify(
          {
            message: eer.response.data.message,
            icon: 'add_alert',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          })
      })

    },
    logout() {
      this.$eventBus.$emit(`update:logout`)
      localStorage.clear();
      this.$router.push({name: 'Login'});
    },

  }
};
</script>
