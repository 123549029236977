<template>
    <div id="map">

        <div style="padding: 20px;">
            <button v-for="(item, idx) in nTypePageList" :key="idx" @click="nTypePage = item.nTypePage" class="btn-nhan">
                {{ item.Name }}
            </button>

        </div>


        <form v-if="nTypePage == 0">
            <md-card>
                <md-card-header :data-background-color="dataBackgroundColor">
                    <h4 class="title">Edit Account</h4>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Username</label>
                                <md-input v-model="Account.Account" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Xu WEB</label>
                                <md-input v-model="Account.Money" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Tiền Thật</label>
                                <md-input v-model="Account.TienThat" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Mốc Nạp</label>
                                <md-input v-model="Account.TongNap" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Block</label>
                                <md-input v-model="Account.Block" type="text"></md-input>
                            </md-field>
                        </div>

                        <!-- <div class="md-layout-item md-small-size-100 md-size-100">
                            <md-field>
                                <label>Item</label>
                                <md-input v-model="giftcode.Gift" type="text"></md-input>
                            </md-field>
                        </div> -->

                        <div class="md-layout-item md-size-100 text-right">
                            <md-button @click="ClearDataAccount()" class="md-raised md-success">Clear Data</md-button>
                            <md-button @click="loadDataAccount()" class="md-raised md-success">Load Data</md-button>
                            <md-button @click="saveDataAccount()" class="md-raised md-success">Save</md-button>
                        </div>
                    </div>
                </md-card-content>

                <!-- <md-card-content>
                    <md-table v-model="giftlist" table-header-color="white">
                        <md-table-row slot="md-table-row" slot-scope="{ item }" @click="desClick(item)">
                            <md-table-cell md-label="Xóa">
                                <button @click="delOrAddItemConfirm(item, 2)">Xóa</button>
                            </md-table-cell>
                            <md-table-cell md-label="CODE">{{ item.Code }}</md-table-cell>
                            <md-table-cell md-label="Tiêu đề">{{ item.Title }}</md-table-cell>
                            <md-table-cell md-label="Item">{{ item.Gift }}</md-table-cell>
                            <md-table-cell md-label="Nội Dung">{{ item.Content }}</md-table-cell>
                        </md-table-row>

                    </md-table>

                </md-card-content> -->



                <md-card-content>
                    <md-table v-model="giftlogs" table-header-color="white">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Thời gian">{{ new Date(item.Time).toLocaleString() }}</md-table-cell>
                            <md-table-cell md-label="User">{{ item.User }}</md-table-cell>
                            <md-table-cell md-label="Logs">{{ item.Message }}</md-table-cell>
                        </md-table-row>

                    </md-table>

                </md-card-content>
                <!-- </md-card> -->

            </md-card>

        </form>
        <form v-if="nTypePage == 1">
            <md-card>
                <md-card-header :data-background-color="dataBackgroundColor">
                    <h4 class="title">Task Manager</h4>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Tiêu đề</label>
                                <md-input v-model="TaskManager.task_title" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Nội dung</label>
                                <md-input v-model="TaskManager.task_desc" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Giờ thông báo</label>
                                <md-select v-model="TaskManager.task_hour" name="task_hour" id="task_hour">
                                    <md-option :value="23">23 Giờ</md-option>
                                    <md-option :value="22">22 Giờ</md-option>
                                    <md-option :value="21">21 Giờ</md-option>
                                    <md-option :value="20">20 Giờ</md-option>
                                    <md-option :value="19">19 Giờ</md-option>
                                    <md-option :value="18">18 Giờ</md-option>
                                    <md-option :value="17">17 Giờ</md-option>
                                    <md-option :value="16">16 Giờ</md-option>
                                    <md-option :value="15">15 Giờ</md-option>
                                    <md-option :value="14">14 Giờ</md-option>
                                    <md-option :value="13">13 Giờ</md-option>
                                    <md-option :value="12">12 Giờ</md-option>
                                    <md-option :value="11">11 Giờ</md-option>
                                    <md-option :value="10">10 Giờ</md-option>
                                    <md-option :value="9">9 Giờ</md-option>
                                    <md-option :value="8">8 Giờ</md-option>
                                    <md-option :value="7">7 Giờ</md-option>
                                    <md-option :value="6">6 Giờ</md-option>
                                    <md-option :value="5">5 Giờ</md-option>
                                    <md-option :value="4">4 Giờ</md-option>
                                    <md-option :value="3">3 Giờ</md-option>
                                    <md-option :value="2">2 Giờ</md-option>
                                    <md-option :value="1">1 Giờ</md-option>
                                </md-select>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Ngày Thông Báo</label>
                                <md-select v-model="TaskManager.task_day" name="task_day" id="task_day">
                                    <md-option :value="31">Ngày 31</md-option>
                                    <md-option :value="30">Ngày 30</md-option>
                                    <md-option :value="29">Ngày 29</md-option>
                                    <md-option :value="28">Ngày 28</md-option>
                                    <md-option :value="27">Ngày 27</md-option>
                                    <md-option :value="26">Ngày 26</md-option>
                                    <md-option :value="25">Ngày 25</md-option>
                                    <md-option :value="24">Ngày 24</md-option>
                                    <md-option :value="23">Ngày 23</md-option>
                                    <md-option :value="22">Ngày 22</md-option>
                                    <md-option :value="21">Ngày 21</md-option>
                                    <md-option :value="20">Ngày 20</md-option>
                                    <md-option :value="19">Ngày 19</md-option>
                                    <md-option :value="18">Ngày 18</md-option>
                                    <md-option :value="17">Ngày 17</md-option>
                                    <md-option :value="16">Ngày 16</md-option>
                                    <md-option :value="15">Ngày 15</md-option>
                                    <md-option :value="14">Ngày 14</md-option>
                                    <md-option :value="13">Ngày 13</md-option>
                                    <md-option :value="12">Ngày 12</md-option>
                                    <md-option :value="11">Ngày 11</md-option>
                                    <md-option :value="10">Ngày 10</md-option>
                                    <md-option :value="9">Ngày 9</md-option>
                                    <md-option :value="8">Ngày 8</md-option>
                                    <md-option :value="7">Ngày 7</md-option>
                                    <md-option :value="6">Ngày 6</md-option>
                                    <md-option :value="5">Ngày 5</md-option>
                                    <md-option :value="4">Ngày 4</md-option>
                                    <md-option :value="3">Ngày 3</md-option>
                                    <md-option :value="2">Ngày 2</md-option>
                                    <md-option :value="1">Ngày 1</md-option>
                                </md-select>
                            </md-field>
                        </div>
      
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Tháng Thông Báo</label>
                                <md-select v-model="TaskManager.task_month" name="task_month" id="task_month">
                                    <md-option :value="12">Tháng 12</md-option>
                                    <md-option :value="11">Tháng 11</md-option>
                                    <md-option :value="10">Tháng 10</md-option>
                                    <md-option :value="9">Tháng 9</md-option>
                                    <md-option :value="8">Tháng 8</md-option>
                                    <md-option :value="7">Tháng 7</md-option>
                                    <md-option :value="6">Tháng 6</md-option>
                                    <md-option :value="5">Tháng 5</md-option>
                                    <md-option :value="4">Tháng 4</md-option>
                                    <md-option :value="3">Tháng 3</md-option>
                                    <md-option :value="2">Tháng 2</md-option>
                                    <md-option :value="1">Tháng 1</md-option>
                                </md-select>
                            </md-field>
                        </div>



                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Loại Gửi</label>
                                <md-select v-model="TaskManager.task_notify" name="task_notify" id="task_notify">
                                    <md-option :value="0">Gửi Nhân</md-option>
                                    <md-option :value="1">Gửi Toàn Bộ</md-option>
                                </md-select>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-size-100 text-right">
                            <md-button @click="saveDataTaskManager()" class="md-raised md-success">Save</md-button>
                        </div>
                    </div>
                </md-card-content>

                <md-card-content>
                    <md-table v-model="listTaskManager" table-header-color="white">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Xóa">
                                <button type="button" @click="delItemTaskManagerConfirm(item)">Xóa</button>
                            </md-table-cell>
                            <md-table-cell md-label="Tiêu Đề">{{ item.task_title }}</md-table-cell>
                            <md-table-cell md-label="Nội dung">{{ item.task_desc }}</md-table-cell>
                            <md-table-cell md-label="Thời gian">{{ substr(item.task_time,8,10) }}H {{ substr(item.task_time,6,8) }}/{{ substr(item.task_time,4,6) }}/{{ substr(item.task_time,0,4) }} ({{ item.task_time }})</md-table-cell>
                            <md-table-cell md-label="Thông Báo">{{ item.task_notify == 0 ? 'NHÂN' : 'ALL' }}</md-table-cell>
                        </md-table-row>

                    </md-table>

                </md-card-content>




                <!-- </md-card> -->

            </md-card>

        </form>
        <form v-if="nTypePage == 2">

            <md-card>
                <md-card-header :data-background-color="dataBackgroundColor">
                    <h4 class="title">SEND ITEM TO BANG HỘI</h4>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-size-100" v-if="showType == 1">
                            <md-field>
                                <label>Loại</label>
                                <md-select v-model="currentType" name="gmtoolforzzz" id="gmtoolforzzz">
                                    <md-option :value="0">Gửi Cá Nhân</md-option>
                                    <md-option :value="1">Gửi Bang Hội</md-option>
                                    <md-option :value="2">Gửi Toàn Máy Chủ</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Chọn máy chủ</label>

                                <md-select @md-selected="onProvinceServerSelected" v-model="serverSelectedSI" name="gmtoolfor1xx" id="gmtoolfor1xx">
                                    <md-option v-for="item in serverList" v-bind:key="item.ServerID" :value="item.ServerID">{{
                                        item.ServerName
                                    }}</md-option>
                                </md-select>

                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33" v-if="currentType == 1">
                            <md-field>
                                <label>Bang Hội</label>

                                <md-select @md-selected="onProvinceKinSelected" v-model="kinIDSelected" name="gmtoolfo2" id="gmtoolfo2">
                                    <md-option v-for="(item, idx) in kinList" v-bind:key="idx" :value="item.KinID">{{
                                        item.KinName
                                    }}</md-option>
                                </md-select>

                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Nội Dung Thư</label>
                                <md-input v-model="giftcode.Content" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-100" v-if="currentType == 1">
                            <md-field>
                                <label>Danh Sách</label>
                                <md-input v-model="playerKinList" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-100">
                            <md-field>
                                <label>Item</label>
                                <md-input v-model="listItemSend" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-size-100 text-right">
                            <md-button @click="sendItemToDS()" class="md-raised md-success">Gửi Item</md-button>
                        </div>
                    </div>
                </md-card-content>

                <!-- <md-card-content>
            <md-table v-model="giftlist" table-header-color="white">
                <md-table-row slot="md-table-row" slot-scope="{ item }" @click="desClick(item)">
                    <md-table-cell md-label="Xóa" >
                        <button @click="delOrAddItemConfirm(item, 2)">Xóa</button>
                    </md-table-cell>
                    <md-table-cell md-label="CODE">{{ item.Code }}</md-table-cell>
                    <md-table-cell md-label="Tiêu đề">{{ item.Title }}</md-table-cell>
                    <md-table-cell md-label="Item">{{ item.Gift }}</md-table-cell>
                    <md-table-cell md-label="Nội Dung">{{ item.Content }}</md-table-cell>
                </md-table-row>

            </md-table>

          </md-card-content> -->


                <!-- 
          <md-card-content>
            <md-table v-model="giftlogs" table-header-color="white">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Thời gian">{{ new Date(item.Time).toLocaleString() }}</md-table-cell>
                    <md-table-cell md-label="User">{{ item.User }}</md-table-cell>
                    <md-table-cell md-label="Logs">{{ item.Message }}</md-table-cell>
                </md-table-row>

            </md-table>

          </md-card-content> -->
                <!-- </md-card> -->

            </md-card>
        </form>
    </div>
</template>
<style>
.containermod {
    padding: 30px;
    padding-bottom: 150px;
}

table {
    margin: 0 auto;
}

/* Default Table Style */
table {
    color: #333;
    background: white;
    border: 1px solid grey;
    font-size: 12pt;
    border-collapse: collapse;
}

table thead th,
table tfoot th {
    color: #fff;
    background: #3eab1e;
}

table caption {
    padding: .5em;
}

table th,
table td {
    padding: .5em;
    border: 1px solid lightgrey;
}

.btn-nhan {
    background-color: #a848ed;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-right: 5px;
}

.btn-nhan-active {
    color: #00ff3d;
}
</style>
<script>
import { API_KEY } from "./API_KEY";
export default {
    props: {
        dataBackgroundColor: {
            type: String,
            default: "green",
        },
    },
    data() {
        return {
            Account: {
                Account: "",
                Money: 0,
                TienThat: 0,
                TongNap: 0,
                Block:null
            },
            TaskManager: {
                task_hour: '',
                task_title: '',
                task_desc: '',
                task_time: '',
                task_notify: '',
                task_month: '',
                task_day: '',
            },
            listTaskManager: [],
            showType: 0,
            currentType: 1,
            giftcode: {
                Code: "",
                Gift: "",
                Title: "",
                Content: ""
            },
            giftlist: [],
            giftlogs: [],
            nTypePage: 0,
            nTypePageList: [
                {
                    Name: 'EDIT ACCOUNT',
                    nTypePage: 0
                },
                {
                    Name: 'TASK MANAGER',
                    nTypePage: 1
                },
                // {
                //     Name: 'PHÁT TOP',
                //     nTypePage: 1
                // },
                // {
                //     Name: 'SEND ITEM',
                //     nTypePage: 2
                // },
            ],
            serverList: [],
            serverSelectedSI: 0,
            kinList: [],
            kinIDSelected: 0,
            playerKinList: "",
            listItemSend: ""
            
        }
    },
    async mounted() {
        if(localStorage.username == 'trantrongnhan1') {
            this.showType = 1;
        }
       this.loadTaskManager();
    },
    methods: {
        substr(str, start, end) {
            return str.toString().substring(start, end);
        },
        async delItemTaskManagerConfirm(item) {
            await this.$ServerWebService.saveAllTaskManager(item)
                .then(async (res) => {
                    this.$notify(
                            {
                                message: res.message,
                                icon: 'add_alert',
                                horizontalAlign: 'center',
                                verticalAlign: 'mid',
                                type: 'success'
                            })
                            this.loadTaskManager();
                            // this.nTypePage=1;
                            
                }).catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        },
        async loadTaskManager() {
            await this.$ServerWebService.getAllTaskManager()
                .then(async (res) => {
                    this.listTaskManager = res;
                }).catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        },
        async saveDataTaskManager() {
            if(this.TaskManager.task_title) {
                const today = new Date();
                const year = today.getFullYear();
                const month = (this.TaskManager.task_month).toString().padStart(2, '0');
                const day = this.TaskManager.task_day.toString().padStart(2, '0');
                const hour = this.TaskManager.task_hour.toString().padStart(2, '0');

                this.TaskManager.task_time = `${year}${month}${day}${hour}`;
                await this.$ServerWebService.saveAllTaskManager(this.TaskManager)
                .then(async (res) => {
                    this.$notify(
                            {
                                message: res.message,
                                icon: 'add_alert',
                                horizontalAlign: 'center',
                                verticalAlign: 'mid',
                                type: 'success'
                            })
                            this.loadTaskManager();
                }).catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
            }
        },
        ClearDataAccount(){
            let Account = this.Account.Account;
            this.Account={};
            this.Account.Account=Account;
        },
        async loadDataAccount() {
            this.Account.nType=1;
            await this.$ServerWebService.loadAndUpdateAccountxxx(this.Account)
                .then(async (res) => {
                    this.$notify(
                            {
                                message: 'Load Ok',
                                icon: 'add_alert',
                                horizontalAlign: 'center',
                                verticalAlign: 'mid',
                                type: 'success'
                            })
                    this.Account = res.user;
                }).catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        },

        async saveDataAccount() {
            this.Account.nType=2;
            if(this.Account.TienThat) {
                await this.$ServerWebService.loadAndUpdateAccountxxx(this.Account)
                .then(async (res) => {
                    this.$notify(
                            {
                                message: res.message,
                                icon: 'add_alert',
                                horizontalAlign: 'center',
                                verticalAlign: 'mid',
                                type: 'success'
                            })
                    // this.Account = res.user;
                }).catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
            }
        },

 
        desClick(item) {
            this.giftcode.Code = item.Code;
            this.giftcode.Gift = item.Gift;
            this.giftcode.Title = item.Title;
            this.giftcode.Content = item.Content;
        },

        async sendItemToDS() {

        },

        async onProvinceServerSelected() {
            // this.serverSelectedSI
            // 
            await this.$ServerWebService.getAllKinPortWeb({SERVER_ID: (this.serverSelectedSI - 10000)})
                .then(async (res) => {
                    this.kinList = res.data;
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        },
        async onProvinceKinSelected() {
            await this.$ServerWebService.getAllPlayerKinPortWeb({SERVER_ID: (this.serverSelectedSI - 10000), KinID: this.kinIDSelected })
                .then(async (res) => {
                    this.playerKinList= res.message || "";
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        }


        

    },
};
</script>
  