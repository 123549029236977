<template>
  <div>
    <md-table v-model="users" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Khuyến Mãi" >
          <button class="success" v-if="item.khuyenmai != null && item.khuyenmai != ''">{{ item.khuyenmai }}</button>
          <button class="error" v-if="item.khuyenmai == null || item.khuyenmai == ''" @click="khuyenmainap(item)">Chưa KM</button>
        </md-table-cell>
        <md-table-cell md-label="X2">{{ (item.sotien * 1.3) < item.xunhan ? 'Đã X2':'' }}</md-table-cell>
        <md-table-cell md-label="Tài khoản">{{ item.username }}</md-table-cell>
        <md-table-cell md-label="Loại nạp">{{ item.type }}</md-table-cell>
        <md-table-cell md-label="Số Tiền">{{ item.sotien == null ? 0 : item.sotien.toLocaleString('en-US') }}</md-table-cell>
        <md-table-cell md-label="Xu Nhận">{{ item.xunhan == null ? 0 :  item.xunhan.toLocaleString('en-US') }}</md-table-cell>
        <md-table-cell md-label="Trạng Thái">
          <button class="success" v-if="item.status == 1">XONG</button>
          <button class="error" v-if="item.status != 1">LỖI</button>
        </md-table-cell>
        <md-table-cell md-label="Thời gian">{{ new Date(item.created).toLocaleString() }}</md-table-cell>
      </md-table-row>
    </md-table>

    <modal name="my-first-modal">
        <!-- <khuyen-mai-form data-background-color="green"> </khuyen-mai-form> -->
        <md-card>
            <md-card-header data-background-color="green">
                <h4 class="title">KHUYẾN MÃI NẠP</h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-33 md-size-33">
                        <md-field>
                            <label>Tài khoản</label>
                            <md-input v-model="user.username" type="text"></md-input>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-33 md-size-33">
                        <md-field>
                            <label>XU WEB</label>
                            <md-select v-model="user.xuwebkhuyenmai" name="xuwebkhuyenmai" id="xuwebkhuyenmai">
                                <md-option value="0">0%</md-option>
                                <md-option value="10">10%</md-option>
                                <md-option value="20">20%</md-option>
                                <md-option value="30">30%</md-option>
                                <md-option value="50">50%</md-option>
                                <md-option value="100">100%</md-option>
                                <md-option value="130">130%</md-option>
                                <md-option value="260">260%</md-option>

                            </md-select>                        
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-small-size-33 md-size-33">
                        <md-field>
                            <label>XU GAME</label>
                            <md-select v-model="user.xugamekhuyenmai" name="xugamekhuyenmai" id="xugamekhuyenmai">
                                <md-option value="0">0</md-option>
                                <md-option value="10">10m</md-option>
                                <md-option value="20">20m</md-option>
                                <md-option value="30">30m</md-option>
                                <md-option value="50">50m</md-option>
                                <md-option value="100">100m</md-option>
                                <md-option value="200">200m</md-option>
                                <md-option value="300">300m</md-option>
                                <md-option value="300">500m</md-option>
                                <md-option value="300">1000m</md-option>
                                <md-option value="300">1500m</md-option>
                            </md-select>                        
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-small-size-33 md-size-33">
                        <md-field>
                            <label>HỘP QUÀ</label>
                            <md-select v-model="user.khuyenmairuong" name="khuyenmairuong" id="khuyenmairuong">
                              <md-option value="0" selected>0</md-option>
                              <md-option value="5">5.000</md-option>
                              <md-option value="10">10.000</md-option>
                              <md-option value="15">15.000</md-option>
                              <md-option value="20">20.000</md-option>
                              <md-option value="25">25.000</md-option>
                              <md-option value="30">30.000</md-option>
                              <md-option value="35">35.000</md-option>
                              <md-option value="40">40.000</md-option>
                              <md-option value="45">45.000</md-option>
                              <md-option value="50">50.000</md-option>
                              <md-option value="55">55.000</md-option>
                              <md-option value="60">60.000</md-option>
                              <md-option value="70">70.000</md-option>
                              <md-option value="80">80.000</md-option>
                              <md-option value="90">90.000</md-option>
                              <md-option value="100">100.000</md-option>
                              <md-option value="150">150.000</md-option>
                              <md-option value="200">200.000</md-option>
                              <md-option value="300">300.000</md-option>
                              <md-option value="400">400.000</md-option>
                              <md-option value="500">500.000</md-option>
                            </md-select>                        
                        </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-33 md-size-33">
                        <md-field>
                            <label>Phụng Huyết Tương</label>
                            <md-select v-model="user.khuyenmainguyenlieuevent" name="khuyenmainguyenlieuevent" id="khuyenmainguyenlieuevent">
                              <md-option value="0" selected>0</md-option>
                              <md-option value="10">10.000</md-option>
                              <md-option value="20">20.000</md-option>
                              <md-option value="30">30.000</md-option>
                              <md-option value="40">40.000</md-option>
                              <md-option value="50">50.000</md-option>
                              <md-option value="55">60.000</md-option>
                              <md-option value="60">70.000</md-option>
                              <md-option value="70">80.000</md-option>
                              <md-option value="80">90.000</md-option>
                              <md-option value="100">100.000</md-option>
                              <md-option value="110">110.000</md-option>
                              <md-option value="120">120.000</md-option>
                              <md-option value="130">130.000</md-option>
                              <md-option value="140">140.000</md-option>
                              <md-option value="150">150.000</md-option>
                              <md-option value="200">200.000</md-option>
                              <md-option value="250">250.000</md-option>
                              <md-option value="300">300.000</md-option>
                              <md-option value="350">350.000</md-option>
                            </md-select>                        
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button @click="formKhuyenMai()" class="md-raised md-success">Khuyến Mãi</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </modal>
  </div>
</template>

<style>
  .success {
    border-radius: 5px;
    color: #fff;
    padding: 3px 10px;
    font-weight: bold;
    border: none;
    background-color: rgb(154, 227, 46);
  }
  .error {
    border-radius: 5px;
    color: #fff;
    padding: 3px 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    background-color: rgb(243, 94, 94);
  }
</style>

<script>
import { KhuyenMaiForm } from "@/pages";
export default {
  name: "ordered-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
      
    },
  },
  // data() {
  //   return {
  //     save_element_chage: null,
  //     type: ['', 'info', 'success', 'warning', 'danger'],
  //     user: {
  //       username: '',
  //       id: 0,
  //       xuwebkhuyenmai: 0,
  //       xugamekhuyenmai: 0,
  //       khuyenmairuong: 0
  //     }
  //   }
  // },
  components: {
    KhuyenMaiForm
  },
  methods: {
    khuyenmainap(id_KM) {
      
      this.$modal.show('my-first-modal');
      this.user.username = id_KM.username;
      this.user.id = id_KM.id;
      this.user.xuwebkhuyenmai = 0;
      this.user.xugamekhuyenmai = 0;
      this.user.khuyenmairuong=0;
      this.user.khuyenmainguyenlieuevent=0;
    }, 
    async formKhuyenMai() {

      if(this.user.username != "")// && this.user.xuwebkhuyenmai >= 0 && this.user.xugamekhuyenmai >= 0
      {
        this.$modal.hide('my-first-modal');
        await this.$ServerWebService.khuyenmainap(this.user)
        .then(r => {
          console.log(r.data.message)
          this.$notify(
                    {
                    message:  r.data.message,
                    icon: 'add_alert',
                    horizontalAlign: 'center',
                    verticalAlign: 'mid',
                    type: this.$type_noti[2]
                    })

          this.users.find(i => i.id == this.user.id).khuyenmai = `${this.user.xuwebkhuyenmai}%, ${this.user.xugamekhuyenmai}M và ${this.user.khuyenmairuong} hộp`;
          
          
        
        })
        .catch(e=> {
          console.log(e);
          // console.log(e.response.data.message);
          // this.notifyVue('bottom','center', 4, )
          alert(e.response.data.message);
        })

      } else {
        this.notifyVue('top','center', 4, "Không đủ thông tin")
      }
      
    },
    notifyVue (verticalAlign, horizontalAlign, color, msg) {
            this.$notify(
                {
                message: msg,
                icon: 'add_alert',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: this.type[color]
                })
        }
  },
  data() {
    return {
      user: {
        username: '',
        id: 0,
        xuwebkhuyenmai: 0,
        xugamekhuyenmai: 0,
        khuyenmairuong: 0,
        khuyenmainguyenlieuevent: 0
      },
      selected: [],
      users: [
      ],
      type: ['', 'info', 'success', 'warning', 'danger'],
    };
  },
  async mounted() {

    // let loader = this.$loading.show({
    //                 // Optional parameters
    //                 loader: 'Dots',
    //                 container: true,
    //                 canCancel: false,
    //                 onCancel: this.onCancel,
    //                 width: 64,
    // height: 64,
    // backgroundColor: '#ffffff',
    // opacity: 0.5,
    // zIndex: 999,
    // });

    await this.$ServerWebService.lichsunap()
    .then(r => {
      this.users = r.data.data;
    })
    .catch(e=> {
    })



    await this.$ServerWebService.laylichsunapxuatmmomo()
    .then(r => {
      this.$eventBus.$emit(`update:momo`, r.data.returnData[0].momo)
      this.$eventBus.$emit(`update:atm`, r.data.returnData[1].atm)
      // loader.hide()

      

    })
    .catch(e=> {
    })
  },
        
};
</script>
