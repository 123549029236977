<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link v-if="!status" to="/login">
        <md-icon>person</md-icon>
        <p>Đăng Nhập</p>
      </sidebar-link>
      <li class="md-list-item">
        <a v-if="status" @click="openLink()">
          <div class="md-list-item-content md-ripple">
            <md-icon>person</md-icon>
            <p>Phiên Bản Cũ</p>
          </div>
        </a>
      </li>
      <!-- <sidebar-link v-if="status && this.nTypeUser > 0" to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Bảng Xếp Hạng</p>
      </sidebar-link> -->
      <!-- <sidebar-link v-if="status && this.nTypeUser <= 2" to="/table">
        <md-icon>content_paste</md-icon>
        <p>Admin Duyệt Thẻ</p>
      </sidebar-link> -->
      <sidebar-link v-if="status && this.nTypeUser <= 2" to="/giftcode">
        <md-icon>content_paste</md-icon>
        <p>GIFT</p>
      </sidebar-link>
      
      <sidebar-link v-if="status && this.nTypeUser > 0" to="/user">
        <md-icon>person</md-icon>
        <p>GM TOOL</p>
      </sidebar-link>
      <sidebar-link v-if="status && this.nTypeUser > 0" to="/maps">
        <md-icon>person</md-icon>
        <p>Cài Đặt Máu & Thưởng</p>
      </sidebar-link>

      <sidebar-link v-if="status && usernameNhan == 'trantrongnhan1'" to="/nhanmanager">
        <md-icon>person</md-icon>
        <p>Nhân Manager</p>
      </sidebar-link>

      
      
      
      <!-- <sidebar-link v-if="status" to="/typography">
        <md-icon>library_books</md-icon>
        <p>Phát Thưởng TOP</p>
      </sidebar-link> -->
      <sidebar-link v-if="status && this.nTypeUser > 0" to="/lam-qua-su-kien">
        <md-icon>bubble_chart</md-icon>
        <p>Tạo Phần Thưởng</p>
      </sidebar-link>
      <!-- <div style="    padding: 35px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;" v-if="status" @click="logout()">
        <md-icon>bubble_chart</md-icon>
        <p>Thoát</p>
      </div> -->
      <!-- <sidebar-link v-if="status" to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Gửi Item</p>
      </sidebar-link>
      <sidebar-link v-if="status" to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>GM TOOL</p>
      </sidebar-link> -->
      <!--<sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notifications</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>unarchive</md-icon>
        <p>Upgrade to PRO</p>
      </sidebar-link> -->
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <div class="manager_server3" v-if="status && (this.nTypeUser == 1 || this.nTypeUser == 2)">
      <div>
          <button @click="turnoffclick()" v-if="status">
            {{ turnon == 1 ? 'Ẩn' : 'Hiện' }}
          </button>
        </div>
    </div>
    
    <!-- <div class="manager_server2" v-bind:class="{ manager_server2_remove: !turnon }">
      
    </div> -->
    

    <div class="manager_server" v-if="status && (this.nTypeUser == 1 || this.nTypeUser == 2)" v-bind:class="{ manager_server2_remove: !turnon }">
          <div class="display: block;">
            <button v-for="(item, idx) in onlines" :key="idx" style="background: #ea4444; color: #fff;">
            SV{{ item.ServerID - 10000 }} : {{ item.Online }} Online
            </button>
          </div>
          <div class="display: block;">
            <button v-for="(item, idx) in serverManager" :key="idx" v-bind:class="activeClass(item.port)" @click="changeserver(item.port, item.server_game_port)">{{ item.name }}</button>
          </div>
        </div>

  </div>
</template>
<style>
.manager_server3 {
  position: fixed;
  z-index: 9999;
  bottom: 20%;
}
.manager_server3 button {
    padding: 5px;
    margin: 3px;
    border: none;
    border-radius: 5px;
    background: bisque;
    font-weight: bold;
    border: 1px solid #cec0c0;
    cursor: pointer;
}
.manager_server2_remove {
  bottom: -25% !important;
}

.manager_server {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}
.manager_server button {
    padding: 5px;
    margin: 3px;
    border: none;
    border-radius: 5px;
    background: bisque;
    font-weight: bold;
    border: 1px solid #cec0c0;
    cursor: pointer;
}
.manager_server button.active {
  background-color: #3eab1e;
  color: #fff;
}
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
// import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    // MobileMenu,
    FixedPlugin,
  },
  data() {
    return {
      usernameNhan: "",
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      type: ['', 'info', 'success', 'warning', 'danger'],
      user: {},
      status: true,
      serverlist: [],
      onlines: [],
      turnon: true,
      link: "",
      nTypeUser:0,
      serverManager: []
    };
  },
  async created() {
 
    // Listening the event hello
    this.$eventBus.$on('update:serverlist', this.handlerServerList);
    this.$eventBus.$on('update:momo', this.handelmomo)
    // this.$eventBus.$on('update:logout', this.handelLogout)
    this.$eventBus.$on('update:login', this.handelLogin)
    this.onlines = [];
    this.usernameNhan=localStorage.username;
    this.nTypeUser=parseInt(localStorage.nType);
    // loadingPlayerOnline
    await this.$ServerWebService.loadServerConfigAll()
      .then(async (res) => {
        let sv = res.data;
        for(let i = 0; i < sv.length; i++) {
          await this.$ServerWebService.loadServerOnline(sv[i].ServerID)
          .then(async (res) => {
            this.onlines.push(res);
          })
          .catch((eer) => {
          })
        }
      })
      .catch((eer) => {
      })

      await this.$ServerWebService.loadServerManagerAll()
      .then(async (res) => {
        let sv = res.data;
        this.serverManager=sv;
                localStorage.serverName=this.serverManager.find(i => i.port == localStorage.defaultPortWeb).name;
      })
      .catch((eer) => {
      })



      
  },
  computed: {
    activeClass: function() {
      return function(x) {
        const defaultPortWeb = localStorage.getItem("defaultPortWeb");
        return x == defaultPortWeb ? "active" : "";
      }
    }
  },
  methods: {
    openLink() {
      this.link=`https://volamcaothu.mobi/linkz?token=${localStorage.token}&username=${localStorage.username}`;
      console.log(this.link);
      // window.open(this.link, '');
      window.location.href = this.link;
    },
    turnoffclick() {
      this.turnon = !this.turnon;
    },
    

    changeserver(port, portGame) {
      localStorage.defaultPortWeb = port;
      localStorage.portGame = portGame;
      localStorage.serverName=this.serverManager.find(i => i.port == port).name;
      this.notifyVue('center','center', 4, 'Đổi thành công')
      location.reload();
      // this.$notify(
      //   {
      //     message: 'Đổi thành công',
      //     icon: 'add_alert',
      //     horizontalAlign: 'center',
      //     verticalAlign: 'mid',
      //     type: this.$type_noti[2]
      //   })
    },
    logout()
    {
      
      this.status=false;
      // this.$eventBus.$emit(`update:logout`)
      localStorage.clear();
      this.$router.push({name: 'Login'});
    },
    handelLogin()
    {
      this.status=true;
    },
    handelLogout()
    {
      this.status=false;
    },
    handelmomo(e) {
      console.log('talll-parent')
    },
    handlerServerList(e) {
      this.serverlist=e;
      console.log(this.serverlist);
    },
        notifyVue (verticalAlign, horizontalAlign, color, msg) {
            this.$notify(
                {
                message: msg,
                icon: 'add_alert',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: this.type[color]
                })
        }
  },
  async mounted() {
    if(localStorage.token) {
      this.status=true
      await this.$ServerWebService.authorization()
        .then(async (result) => {


          let AccountList = this.$admin_list;
            let statusss= 0;
                AccountList.forEach(i => {
                  if (i.Account == result.data.Account || i.Account == result.data.username) {
                    statusss=1;
                  }
                });
                
            
            if((result.data.Account || result.data.username) && statusss == 1)
            {
              this.nTypeUser=parseInt(localStorage.nType);
                this.user = result.data;
                
            }
            else {

                localStorage.removeItem('token');
                this.$router.push({name: 'Login'});
            }
        }).catch((err) => {
          this.status=false
          this.notifyVue('top','center', 4, err.response.data.message)
          localStorage.removeItem('token');
        })
    } else {
      this.status=false
      this.$router.push({name: 'Login'});
    }
  }
};
</script>
