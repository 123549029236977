<template>
  <div class="content"> 
    <md-card-content>
      <div class="md-layout">
    <div class="md-layout-item md-small-size-33 md-size-33">
                <md-field>
                  <label>Gộp Đoạn 1</label>
                  <md-input v-model="user.szMaItem1" @input="onChangeItemMerge" type="text"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-33 md-size-33">
                <md-field>
                  <label>Gộp Đoạn 2</label>
                  <md-input v-model="user.szMaItem2" @input="onChangeItemMerge" type="text"></md-input>
                </md-field>
              </div>
            </div>
            </md-card-content>
    <div class="md-layout">
      <form>
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Làm Item Thưởng</h4>
            <p class="category">Item bị xóa trước đó: {{ removeItemBefore }}</p>
          </md-card-header>

          <md-card-content>
            <div class="md-layout-item md-small-size-33 md-size-33">
              <md-field>
                <label>Loại Tìm Kiếm</label>
                <md-select @md-selected="onProvinceItemSelected" v-model="itemSelected" name="itemSelected"
                  id="itemSelected">
                  <md-option v-for="(item, idx) in fullListItem" v-bind:key="idx" :value="idx">{{ item.des }}</md-option>
                </md-select>
              </md-field>

            </div>

            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-100">
                <md-field>
                  <label>Mã Item</label>
                  <md-input v-model="user.szMaItem" @input="onChangeItemChoose" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>Tên Item hoặc ID Item</label>
                  <md-input @input="itemChange" v-model="user.ItemName" type="text"></md-input>
                </md-field>
              </div>



              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>Số Lượng</label>
                  <md-input v-model="user.SoLuong" type="number"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-33">
                <md-field>
                  <label>ID Item</label>
                  <md-input disabled v-model="user.ItemID" type="text"></md-input>
                </md-field>
              </div>
              <!-- @input="itemChange"  -->

              <div class="md-layout-item md-size-100 text-right">
                <!-- <md-button @click="lammoiall()" class="md-raised md-success">Clear All</md-button> -->
                <md-button @click="lammoiall()" class="md-raised md-success">Làm Mới</md-button>
                <md-button @click="addItem()" class="md-raised md-success">Thêm Item</md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>




    </div>

    <div>
      <h3 v-if="ItemHoanChinh">Item hoàn chỉnh</h3>
      <h3 v-if="!ItemHoanChinh" style="color:red; font-weight: bold;">Item không hoàn chỉnh</h3>

      <div class="md-layout-item md-medium-size-66 md-xsmall-size-66 md-size-66">
        <md-card class="md-card-plain">
          <md-card-header data-background-color="green">
            <h4 class="title">ID đang soạn</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="ItemListChoose" table-header-color="green">
              <!-- @click.native="desClick(item)" -->
              <md-table-row slot="md-table-row" slot-scope="{ item }"
                >
                <md-table-cell md-label="Loại">{{ item.ItemType }}</md-table-cell>
                <md-table-cell md-label="Item ID">{{ item.ItemType == "DarkSteel" ? '' : item.ItemID }}</md-table-cell>
                <md-table-cell md-label="Tên Item">{{ item.ItemType == "DarkSteel" ? 'Ngọc Thạch' : item.ItemType == "IndifferHonor" ? 'Điểm Tâm Ma' : item.ItemType == "BasicExp" ? 'Điểm kinh nghiệm' : getItemNameById(item.ItemID) }}</md-table-cell>
                <md-table-cell md-label="Số Lượng">{{ formatPrice(item.ItemSL) }}</md-table-cell>

              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card class="md-card-plain">
          <md-card-header data-background-color="green">
            <h4 class="title">Thông tin tìm được</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="ItemList" table-header-color="green">
              <!-- @click.native="desClick(item)" -->
              <md-table-row slot="md-table-row" slot-scope="{ item }"
                @click.native="desClick(item.ItemID, item.ItemName)">
                <md-table-cell md-label="Item ID">{{ item.ItemID }}</md-table-cell>
                <md-table-cell md-label="Tên Item">{{ item.ItemName }}</md-table-cell>

              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>


    </div>
    <audio ref="sound" :src="soundSrc"></audio>
  </div>
</template>

<style>
.mytbaaa, .mytbaaa th, .mytbaaa td {
  border: 1px solid;
}
.mytbaaa td {
  padding: 5px 20px;
}
.mytbaaa th {
  padding: 5px 5px;
}
</style>

<script>
export default {
  data() {
    return {
      currentListIndex: 0,
      itemSelected: 0,
      user: {
        ItemID: "",
        ItemName: "",
        SoLuong: 1,
        szMaItem: "",
        szMaItem1: "",
        szMaItem2: ""
      },
      ItemList: [],
      ItemListClone: [],
      fullListItem: [],
      defaultLimitItem: 10,
      removeItemBefore: "",
      ItemListChoose: [],
      ItemHoanChinh: true,
      soundSrc: "https://assets.staticimg.com/trade-web/4.2.23/voice/keyboard_input.mp3"
    };
  },
  methods: {
    formatPrice(val) {
      if (!val)  {
        val=''
      }
        // let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getItemNameById(ID) {
      if(ID == undefined) {
        return "";
      }
      let item = this.ItemListClone.find(i => i.ItemID == ID)
      if (item) {
        return item.ItemName;
      } else {
        return "";
      }
    },
    onSelect: function (items) {
      this.selected = items;
    },
    addItem() {
      if (this.user.ItemID != "" && this.user.SoLuong != "") {
        let s = "Item," + this.user.ItemID + "," + this.user.SoLuong;
        this.user.szMaItem = this.user.szMaItem == "" ? s : this.user.szMaItem + ";" + s
        this.user.ItemID = "";
      }
      // this.user.ItemName="";
    },
    itemChange() {

      if (this.user.ItemName.includes(",")) {
        var allSearch = this.user.ItemName.split('|');
        console.log(allSearch);
        let arrTemp = [
        ...this.ItemListClone,
        ...[{
          ItemID: 227,
          ItemName: "Thủy Tinh Vàng",
          FullSearch: "227 - thủy tinh vàng"
        },
        {
          ItemID: "DarkSteel",
          ItemName: "Ngọc Thạch",
          FullSearch: "Ngọc Thạch"
        }
        ]
      ]
        var resulut = "";
        var itemlist=[];
        if (allSearch.length > 0) {
          for (let i=0; i< allSearch.length; i++) {
            var arrItem = allSearch[i].split(',');
            this.ItemList = arrTemp.filter(i => i.FullSearch.includes(arrItem[0].toLowerCase())).slice(0, this.defaultLimitItem);
            if (this.ItemList.length == 1) {

              if (resulut.length != "") {
                resulut += ';';
              }
              if (this.itemlist[0].ItemID == "DarkSteel") {
                resulut += `DarkSteel,${arrItem[1]}`;
              } else {
                resulut += `Item,${this.ItemList[0].ItemID},${arrItem[1]}`;
                itemlist.push(this.ItemList[0]);
              }
              
              
            }
          }
          this.user.szMaItem=resulut;
          this.ItemList = itemlist;
        }
      
      } else {
        let arrTemp = [
        ...this.ItemListClone,
        ...[{
          ItemID: 227,
          ItemName: "Thủy Tinh Vàng",
          FullSearch: "227 - thủy tinh vàng"
        }]
      ]
     

      this.ItemList = [];
      this.ItemList = arrTemp.filter(i => (i.FullSearch.includes(this.user.ItemID.toLowerCase()) && this.user.ItemID != "") || (i.FullSearch.includes(this.user.ItemName.toLowerCase()) && this.user.ItemName != "")).slice(0, this.defaultLimitItem);
      this.ItemList = this.ItemList.filter(i => i.ItemID != "2527")

      }

      // this.onChangeItemChoose(this.user.szMaItem);
    },
    lammoi() {
      this.user.ItemID = "";
      this.user.ItemName = "";
      // this.user.szMaItem="";
      this.user.SoLuong = 1;
    },
    lammoiall() {
      if (this.user.szMaItem != "") {
        this.removeItemBefore = this.user.szMaItem;
        this.user.ItemID = "";
        this.user.ItemName = "";
        this.user.szMaItem = "";
        this.user.SoLuong = 1;
      }
    },
    nameChange() {

    },
    desClick(id, name) {
      this.user.ItemID = id;
      // this.user.ItemName=name;
    },
    onProvinceItemSelected() {
      // console.log(this.itemSelected);
      this.ItemListClone = this.fullListItem[this.itemSelected].data;
      this.ItemList = [];

      this.ItemList = this.ItemListClone.slice(-this.defaultLimitItem);
    },

    onChangeItemMerge() {
      this.user.szMaItem="";
      if (this.user.szMaItem1 && this.user.szMaItem2) {
        var itemMap = new Map();
        // Chia chuỗi b1 thành các mục và thêm vào Map
        var b1Items = this.user.szMaItem1.split(";");
        b1Items.forEach(function (item) {
          var parts = item.split(",");
          var code = parts[1];
          var quantity = parseInt(parts[2]);

          if (itemMap.has(code)) {
            itemMap.set(code, itemMap.get(code) + quantity);
          } else {
            itemMap.set(code, quantity);
          }
        });

        // Chia chuỗi b thành các mục và thêm vào Map
        var bItems = this.user.szMaItem2.split(";");
        bItems.forEach(function (item) {
          var parts = item.split(",");
          var code = parts[1];
          var quantity = parseInt(parts[2]);

          if (itemMap.has(code)) {
            itemMap.set(code, itemMap.get(code) + quantity);
          } else {
            itemMap.set(code, quantity);
          }
        });

        // Tạo chuỗi kết quả từ dữ liệu trong Map
        var mergedResult = "Item," + Array.from(itemMap.entries()).map(function ([code, quantity]) {
          return code + "," + quantity;
        }).join(";Item,");
        this.user.szMaItem=mergedResult;
      }
    },
    onChangeItemChoose() {
      // ItemListChoose
      let itemInput = this.user.szMaItem;
                this.ItemListChoose=[];
                if(itemInput.length > 0) {
                    // Item,111171,30000;Item,111172,30000;Item,111173,20000;Item,111162,4
                    
                    let 
                    items = itemInput.split(';')
                    let tbody = "";
                    let err = 0;
                    for(let i = 0; i < items.length; i++)
                    {
                        let one = items[i];
                        let oneArr = one.split(',');
                        if (oneArr.length != 3 && oneArr[0] != "DarkSteel") {
                            err+=1;
                        }
                        if(isNaN(parseInt(oneArr[2])) && oneArr[0] != "DarkSteel") {
                          err+=1;
                        }
                        if(oneArr[0] != "item" && oneArr[0] != "Item" && oneArr[0] != "IndifferHonor" && oneArr[0] != "BasicExp" && oneArr[0] != "DarkSteel") {
                          err+=1;
                        }
                        this.ItemListChoose.push({
                          ItemName: 1,
                          ItemType: oneArr[0],
                          ItemID: oneArr[1],
                          ItemSL: oneArr[2] || oneArr[1]
                        });
                    }
                    if (err > 0) {
                      this.ItemHoanChinh=false;
                        // $('#msg_check_item').text('Item bị lỗi vui lòng kiểm tra lại');
                    } else {
                      this.ItemHoanChinh=true;
                        // $('#msg_check_item').text('Item hoàn chỉnh');
                    }
                    // $('#tbItemTB').empty();
                    // $('#tbItemTB').append(tbody);
                } else {
                  this.ItemHoanChinh=true;
                }
    }
  },
  async mounted() {

    // const textInputs = document.querySelectorAll('input[type="text"]');
    // textInputs.forEach(input => {
    //   input.addEventListener('input', () => {
    //     this.$refs.sound.play();
    //   });
    // });

    await this.$ServerWebService.dulieuItemLamQuaSK()
      .then(async (res) => {
        this.fullListItem = res.data;
       

        this.ItemListClone =[...this.fullListItem[0].data];

        
        
        
        // [...this.fullListItem[0].data];
        this.itemSelected = this.fullListItem.length - 1;
        this.ItemList = this.ItemListClone.slice(-this.defaultLimitItem);


        





        // console.log(this.ItemList);

        // if(!res.data.token) {
        //     this.notifyVue('top','center', 4, res.data.message)
        // } else {
        //     this.notifyVue('top','center', 2, res.data.message)
        //     localStorage.token  = res.data.token;
        //     localStorage.username = this.user.Account;
        //     localStorage.defaultPortWeb = this.adminlist.find(i => i.Account == this.user.Account).port

        //     await this.$ServerWebService.loadingServerList()
        //     .then((r) => {
        //         this.$eventBus.$emit(`update:serverlist`, r.data);
        //     })
        //     .catch((e) => {
        //     })
        // }
      })
      .catch((eer) => {
        this.$notify(
          {
            message: eer.response.data.message,
            icon: 'add_alert',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          })
      })
  },
};
</script>
