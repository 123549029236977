<template>
    <div id="map">

        <div style="padding: 20px;">
            <button v-for="(item, idx) in nTypePageList" :key="idx" @click="typePageClick(item.nTypePage)" class="btn-nhan">
                {{ item.Name }}
            </button>

        </div>


        <form v-if="nTypePage == 0">
            <md-card>
                <md-card-header :data-background-color="dataBackgroundColor">
                    <h4 class="title">TẠO GIFTCODE</h4>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Mã GIFTCODE</label>
                                <md-input v-model="giftcode.Code" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Tiêu Đề CODE</label>
                                <md-input v-model="giftcode.Title" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Nội Dung CODE</label>
                                <md-input v-model="giftcode.Content" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-100">
                            <md-field>
                                <label>Item</label>
                                <md-input v-model="giftcode.Gift" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-size-100 text-right">
                            <md-button @click="delOrAddItemConfirm(giftcode, 1)" class="md-raised md-success">Thêm
                                Code</md-button>
                        </div>
                    </div>
                </md-card-content>

                <!-- <md-card class="md-card-plain"> -->
                <md-card-content>
                    <md-table v-model="giftlist" table-header-color="white">
                        <md-table-row slot="md-table-row" slot-scope="{ item }" @click="desClick(item)">
                            <md-table-cell md-label="Xóa">
                                <button @click="delOrAddItemConfirm(item, 2)">Xóa</button>
                                <!-- <button class="success" v-if="item.khuyenmai != null && item.khuyenmai != ''">{{ item.khuyenmai }}</button>
                    <button class="error" v-if="item.khuyenmai == null || item.khuyenmai == ''" @click="khuyenmainap(item)">Chưa KM</button> -->
                            </md-table-cell>
                            <md-table-cell md-label="CODE">{{ item.Code }}</md-table-cell>
                            <md-table-cell md-label="Tiêu đề">{{ item.Title }}</md-table-cell>
                            <md-table-cell md-label="Item">{{ item.Gift }}</md-table-cell>
                            <md-table-cell md-label="Nội Dung">{{ item.Content }}</md-table-cell>
                        </md-table-row>

                    </md-table>

                </md-card-content>



                <md-card-content>
                    <md-table v-model="giftlogs" table-header-color="white">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Thời gian">{{ new Date(item.Time).toLocaleString() }}</md-table-cell>
                            <md-table-cell md-label="User">{{ item.User }}</md-table-cell>
                            <md-table-cell md-label="Logs">{{ item.Message }}</md-table-cell>
                        </md-table-row>

                    </md-table>

                </md-card-content>
                <!-- </md-card> -->

            </md-card>

        </form>

        <form v-if="nTypePage == 2">

            <md-card>
                <md-card-header :data-background-color="dataBackgroundColor">
                    <h4 class="title">SEND ITEM TO BANG HỘI</h4>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-size-100" v-if="showType == 1">
                            <md-field>
                                <label>Loại</label>
                                <md-select v-model="currentType" name="gmtoolforzzz" id="gmtoolforzzz">
                                    <md-option :value="0">Gửi Cá Nhân</md-option>
                                    <md-option :value="1">Gửi Bang Hội</md-option>
                                    <md-option :value="2">Gửi Toàn Máy Chủ</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Chọn máy chủ</label>

                                <md-select @md-selected="onProvinceServerSelected" v-model="serverSelectedSI" name="gmtoolfor1xx" id="gmtoolfor1xx">
                                    <md-option v-for="item in serverList" v-bind:key="item.ServerID" :value="item.ServerID">{{
                                        item.ServerName
                                    }}</md-option>
                                </md-select>

                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33" v-if="currentType == 1">
                            <md-field>
                                <label>Bang Hội</label>

                                <md-select @md-selected="onProvinceKinSelected" v-model="kinIDSelected" name="gmtoolfo2" id="gmtoolfo2">
                                    <md-option v-for="(item, idx) in kinList" v-bind:key="idx" :value="item.KinID">{{
                                        item.KinName
                                    }}</md-option>
                                </md-select>

                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-33">
                            <md-field>
                                <label>Nội Dung Thư</label>
                                <md-input v-model="giftcode.Content" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-size-100" v-if="currentType == 1">
                            <md-field>
                                <label>Danh Sách</label>
                                <md-input v-model="playerKinList" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-size-100">
                            <md-field>
                                <label>Item</label>
                                <md-input v-model="listItemSend" type="text"></md-input>
                            </md-field>
                        </div>

                        <div class="md-layout-item md-size-100 text-right">
                            <md-button @click="sendItemToDS()" class="md-raised md-success">Gửi Item</md-button>
                        </div>
                    </div>
                </md-card-content>

                <!-- <md-card-content>
            <md-table v-model="giftlist" table-header-color="white">
                <md-table-row slot="md-table-row" slot-scope="{ item }" @click="desClick(item)">
                    <md-table-cell md-label="Xóa" >
                        <button @click="delOrAddItemConfirm(item, 2)">Xóa</button>
                    </md-table-cell>
                    <md-table-cell md-label="CODE">{{ item.Code }}</md-table-cell>
                    <md-table-cell md-label="Tiêu đề">{{ item.Title }}</md-table-cell>
                    <md-table-cell md-label="Item">{{ item.Gift }}</md-table-cell>
                    <md-table-cell md-label="Nội Dung">{{ item.Content }}</md-table-cell>
                </md-table-row>

            </md-table>

          </md-card-content> -->


                <!-- 
          <md-card-content>
            <md-table v-model="giftlogs" table-header-color="white">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Thời gian">{{ new Date(item.Time).toLocaleString() }}</md-table-cell>
                    <md-table-cell md-label="User">{{ item.User }}</md-table-cell>
                    <md-table-cell md-label="Logs">{{ item.Message }}</md-table-cell>
                </md-table-row>

            </md-table>

          </md-card-content> -->
                <!-- </md-card> -->

            </md-card>
        </form>

        <form v-if="nTypePage == 4">

        <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
                <h4 class="title">SETUP OPEN</h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Chọn máy chủ</label>

                            <md-select v-model="serverSelectedSI" name="gmtoolfor1xx2" id="gmtoolfor1xx2">
                                <md-option v-for="item in serverList" v-bind:key="item.ServerID" :value="item.ServerID">
                                {{
                                    item.ServerName
                                }}
                                </md-option>
                            </md-select>

                        </md-field>
                    </div>

                   
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button @click="submitSetupNewServer()" class="md-raised md-success">Bắt đầu</md-button>
                    </div>
                </div>
            </md-card-content>

        </md-card>
        </form>

        <form v-if="nTypePage == 1">

        <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
                <h4 class="title">TÌM KIẾM VÀ FIX SKILL</h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-field>
                            <label>Tên Skill</label>
                            <md-input @input="itemChange" v-model="search_skillname" type="text"></md-input>
                        </md-field>
                        
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-field>
                            <label>Mô tả:</label>
                            <md-textarea v-model="textarea"></md-textarea>
                        </md-field>
                    </div>

                    
                </div>
            </md-card-content>


            <md-card-content>
                    <md-table v-model="skilllist" table-header-color="white">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="SkillId">{{ item.SkillId ? item.SkillId : '-' }}</md-table-cell>
                            <md-table-cell md-label="SkillName">{{ item.SkillName ? item.SkillName : '-' }}</md-table-cell>
                            <md-table-cell md-label="Property">{{ item.Property ? item.Property : '-' }}</md-table-cell>
                            <md-table-cell md-label="ClassName">{{ item.ClassName ? item.ClassName : '-' }}</md-table-cell>
                            <md-table-cell md-label="MagicDesc">{{ item.MagicDesc ? item.MagicDesc : '-' }}</md-table-cell>
                        </md-table-row>

                    </md-table>

            </md-card-content>

        </md-card>
        </form>

        <form v-if="nTypePage == 3">
        <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
                <h4 class="title">GỘP SERVER</h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Chọn máy chủ gộp từ</label>

                            <md-select @md-selected="onProvinceServerSelectedX" v-model="server_merge.ServerFrom" name="gmtoolfor1xxx" id="gmtoolfor1xxx">
                                <md-option v-for="item in serverList" v-bind:key="item.ServerID" :value="item.ServerID">{{
                                    item.ServerName
                                }}</md-option>
                            </md-select>

                        </md-field>
                    </div>


                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Chọn máy chủ gộp về</label>

                            <md-select @md-selected="onProvinceServerSelectedX" v-model="server_merge.ServerTo" name="gmtoolfor1xx" id="gmtoolfor1xx">
                                <md-option v-for="item in serverList" v-bind:key="item.ServerID" :value="item.ServerID">{{
                                    item.ServerName
                                }}</md-option>
                            </md-select>

                        </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Tên Mới của máy chủ</label>
                            <md-input v-model="server_merge.NewName" type="text"></md-input>
                        </md-field>
                    </div>


                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Số bang giữ lại</label>

                            <md-select v-model="server_merge.soBang" name="gxmtoolfor1xx" id="gmtoolfor1xx">
                                <md-option :value="3">3</md-option>
                                <md-option :value="4">4</md-option>
                                <md-option :value="5">5</md-option>
                            </md-select>

                        </md-field>
                    </div>

           
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button @click="sendMerge()" class="md-raised md-success">Bắt đầu gộp</md-button>
                    </div>
                </div>
            </md-card-content>

        </md-card>
        </form>
    </div>
</template>
<style>
.containermod {
    padding: 30px;
    padding-bottom: 150px;
}

table {
    margin: 0 auto;
}

/* Default Table Style */
table {
    color: #333;
    background: white;
    border: 1px solid grey;
    font-size: 12pt;
    border-collapse: collapse;
}

table thead th,
table tfoot th {
    color: #fff;
    background: #3eab1e;
}

table caption {
    padding: .5em;
}

table th,
table td {
    padding: .5em;
    border: 1px solid lightgrey;
}

.btn-nhan {
    background-color: #a848ed;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-right: 5px;
}

.btn-nhan-active {
    color: #00ff3d;
}
</style>
<script>
import { API_KEY } from "./API_KEY";
export default {
    props: {
        dataBackgroundColor: {
            type: String,
            default: "green",
        },
    },
    data() {
        return {
            showType: 0,
            currentType: 1,
            giftcode: {
                Code: "",
                Gift: "",
                Title: "",
                Content: ""
            },
            server_merge: {
                ServerFrom: "",
                ServerTo: "",
                NewName: "",
                soBang: 3
            },
            giftlist: [],
            giftlogs: [],
            nTypePage: 0,
            nTypePageList: [
                {
                    Name: 'GIFTCODE',
                    nTypePage: 0
                },
                {
                    Name: 'FIX SKILL',
                    nTypePage: 1
                },
                // {
                //     Name: 'SEND ITEM',
                //     nTypePage: 2
                // },
                {
                    Name: 'GỘP SERVER',
                    nTypePage: 3
                },
                {
                    Name: 'OPEN-SERVER-SETUP',
                    nTypePage: 4
                },
            ],
            serverList: [],
            serverSelectedSI: 0,
            kinList: [],
            kinIDSelected: 0,
            playerKinList: "",
            listItemSend: "",
            skilllist: [],
            skilllistclone: [],
            search_skillname:"",
            textarea: ""

        }
    },
    async mounted() {
        await this.loadingData();
        if(localStorage.username == 'trantrongnhan1') {
            this.showType = 1;
        }
    },
    methods: {
        async onProvinceServerSelectedX() {

            var A = this.serverList.find(i => i.ServerID == this.server_merge.ServerFrom)
            var B = this.serverList.find(i => i.ServerID == this.server_merge.ServerTo)
            if (A && B)
            {
                var S1 = A.ServerName.split('-')[0].trim();
                var S2 = B.ServerName.split('-')[0].trim();

                this.server_merge.NewName = S1 + '~' + S2 + " - SV GOP";
            }
        },
        async sendMerge() {
            if (confirm('Đồng ý gửi lệnh?')) {
                await this.$ServerWebService.mergeTwoServer(this.server_merge)
                .then(async (res) => {
                    console.log(res);
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
            }
        },
        itemChange() {
            this.skilllist = [];
            // for(let i=1; i < this.skilllistclone.length; i++) {
            //     if (this.skilllistclone[i] == undefined) {
            //         console.log(this.skilllistclone[i]);
            //     }
            // }
            console.log(this.search_skillname.toLowerCase());
            this.skilllist = this.skilllistclone.filter(i => i.SkillName.includes(this.search_skillname.toLowerCase())).slice(0, 12);
        },
        async typePageClick(nTypePage) {
            this.nTypePage =nTypePage;
            if (this.nTypePage == 1) {
                //load Skill ALL
                await this.$ServerWebService.loadSkillToFix()
                .then(async (res) => {
                    this.skilllistclone = res.filter(i => i.SkillName != undefined);;
                    if (this.skilllistclone.length > 0) {
                        this.skilllist = [this.skilllistclone[0]]
                    }
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
            }
        },
        async loadingData() {
            this.giftlist = [];
            await this.$ServerWebService.allGiftCode()
                .then(async (res) => {
                    this.giftlist = res.data;
                    this.giftlogs = res.logs;
                    this.serverList = this.$serverList;
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        },
        desClick(item) {
            this.giftcode.Code = item.Code;
            this.giftcode.Gift = item.Gift;
            this.giftcode.Title = item.Title;
            this.giftcode.Content = item.Content;
        },
        async delOrAddItemConfirm(item, type) {
            if (confirm('Đồng ý gửi lệnh?')) {
                this.delOrAddItem(item, type);
            }
        },
        async delOrAddItem(item, type) {
            var data_send = item;
            if (type == 1) {
                data_send = this.giftcode;
            }
            if (data_send.Code == "" || data_send.Gift == "" || data_send.Title == "" || data_send.Content == "") {
                alert('Không đủ dữ liệu !');
            } else {
                let fild = this.giftlist.filter(i => i.Code == data_send.Code);
                if (fild.length > 0 && type == 1) {
                    alert('Code này đã tồn tại');
                    return
                }
                data_send.type = type;
                await this.$ServerWebService.createOrDelGift(data_send)
                    .then(async (res) => {
                        this.$notify(
                            {
                                message: res.message,
                                icon: 'add_alert',
                                horizontalAlign: 'center',
                                verticalAlign: 'top',
                                type: 'success'
                            })
                        await this.loadingData();
                    })
                    .catch((eer) => {
                        this.$notify(
                            {
                                message: eer.response.data.message,
                                icon: 'add_alert',
                                horizontalAlign: 'center',
                                verticalAlign: 'top',
                                type: 'danger'
                            })
                    })
            }
        },

        async sendItemToDS() {

        },

        async submitSetupNewServer() {
            await this.$ServerWebService.submitnewserver({SERVER_ID: (this.serverSelectedSI - 10000)})
                .then(async (res) => {
                    this.$notify(
                        {
                            message: 'Thành công!',
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'success'
                        })
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })

        },

        async onProvinceServerSelected() {
            // this.serverSelectedSI
            // 
            await this.$ServerWebService.getAllKinPortWeb({SERVER_ID: (this.serverSelectedSI - 10000)})
                .then(async (res) => {
                    this.kinList = res.data;
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        },
        async onProvinceKinSelected() {
            await this.$ServerWebService.getAllPlayerKinPortWeb({SERVER_ID: (this.serverSelectedSI - 10000), KinID: this.kinIDSelected })
                .then(async (res) => {
                    this.playerKinList= res.message || "";
                })
                .catch((eer) => {
                    this.$notify(
                        {
                            message: eer.response.data.message,
                            icon: 'add_alert',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        })
                })
        }


        

    },
};
</script>
  