import axios from 'axios';

// const host = 'http://103.163.214.34:6001/';
const host = 'https://volamcaothu.mobi/localhost:6001/';

class ServerGameService {
    static loadDefaultPort() {
        return localStorage.defaultPortWeb;
    }

    static getAllTaskManager() {
        let url = host.replace('6001', '6001') + 'api/admin/task_manager';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(url, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }

    static saveAllTaskManager(request) {
        let url = host.replace('6001', '6001') + 'api/admin/task_manager';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }
    

    static mergeTwoServer(request) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/auto/gopsv';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static loadSkillToFix() {
        let url = host.replace('6001', '6001') + 'api/auth/read-content-skill';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(url, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static loadAndUpdateAccountxxx(request) {
        let url = host.replace('6001', '6001') + 'api/nhan/GetInfoAccount2';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }

    

    static getAllPlayerKinPortWeb(request) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/kin-menber-by-server-id-kin-id';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static getAllKinPortWeb(request) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/kin-by-server-id';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static submitnewserver(request) {
    let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/newserver';
    return new Promise(async (resolve, reject) => {
        request.nPortWeb = parseInt(this.loadDefaultPort());
        try {
            await axios.post(url, request, {
                headers: {
                  authorization: `Bearer ${localStorage.token}`,
                  'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            })
            
        } catch(err) {
            reject(err);
        }
    })
}

    static getAllKinPortWeb(request) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/kin-by-server-id';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }

    static getRankBoard(ServerID) {
        let url = host.replace('6001', '6001') + 'api/nhan/rank?portgame=' + localStorage.portGame + '&ServerCode=' + ServerID + '&portWeb=' + this.loadDefaultPort();
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }


    static createOrDelGift(request) {
        let url = host.replace('6001', '6001') + 'api/nhan/giftcode';
        return new Promise(async (resolve, reject) => {
            request.nPortWeb = parseInt(this.loadDefaultPort());
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static allGiftCode(ServerID) {
        let url = host.replace('6001', '6001') + 'api/nhan/giftcode?port=' + this.loadDefaultPort();
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }


    static loadServerOnline(ServerID) {
        let url = host.replace('6001', '6001') + 'api/nhan/playerOnline?ServerCode='+ ServerID + '&' + 'portgame=' + localStorage.portGame + '&portWeb=' + this.loadDefaultPort();
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }


    static loadServerConfigAll() {
        let url = host.replace('6001', '6001') + 'api/nhan/config-svall-list?portWeb=' + this.loadDefaultPort();
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then(async (res) => {

                    // let listServerConfig = ;

                    // await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                    // .then((res) => {


                    // }).catch((err) => {
                    //     reject(err);
                    // })

                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    static loadServerManagerAll() {
        let url = host.replace('6001', '6001') + 'api/auth/manger-server-list';
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }


    static submitPhatTOPVideo(request) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/phatthuongtopvideo';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static submitSaveThuongVaMau(request) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/auth/mauvathuongserverpost';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }


    static loadServerConfigHP() {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/auth/mauvathuongserver';
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    static submitGMTOOL(request) {
        let url = host.replace('6001', '6001') + 'api/auth/submit-gm-tool';
        request.portWeb = this.loadDefaultPort();
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, request, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                })
                
            } catch(err) {
                reject(err);
            }
        })
    }

    static loadServerGame() {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/auth/config-server-list';
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    //savedulieuduatoplc
    static dulieuItemLamQuaSK() {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/auth/item';
        return new Promise(async (resolve, reject) => {
            try {
                await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    //savedulieuduatoplc
    static savedulieuduatoplc(user) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/gm/save-du-lieu-dua-top-lc';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(url, user)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    //savedulieuduatoplc
    static dulieuduatop(user) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/gm/lay-du-lieu-dua-top';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(url, user)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }


    //Khuyen Mai
    static xulikeshare(user) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/tang-xu-like-share';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, user)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    //Khuyen Mai
    static khuyenmainap(user) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/km-nap';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, user)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }
    // , headers: {authorization: `Bearer ${localStorage.token}`
    //Khuyen Mai
    static adminduyetnap(user) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/admin-duyet-xu-bi-loi';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, user, {
                    headers: {
                      authorization: `Bearer ${localStorage.token}`,
                      'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }


    //Login
    static login(user, port = 6001) {
        let url = host.replace('6001', port) + 'api/auth/login';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(url, user)
                .then((res) => {
                    const result = {message: res.statusText, data: res.data}
                    resolve(result);
                }).catch(async (err) => {
                    reject(err)
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    static loadingServerList(port = 6001) {
        let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/config-server-list';
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(url, {})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
            } catch(err) {
                reject(err);
            }
        })
    }

    static authorization(port=6001) {
        return new Promise(async (resolve, reject) => {
            let url = host.replace('6001', '6001') + 'api/auth/authorization';
            await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }

    static lichsunap() {
        return new Promise(async (resolve, reject) => {
            let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/lich-su-nap';
            await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }
    static laylichsunapxuatmmomo() {
        return new Promise(async (resolve, reject) => {
            let url = host.replace('6001', this.loadDefaultPort()) + 'api/admin/get-lich-su-giao-dich-atm-momo';
            await axios({method: 'GET', url: url, headers: {authorization: `Bearer ${localStorage.token}`}})
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }
}
export default ServerGameService;