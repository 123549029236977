<template>
    <form>
        <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
                <h4 class="title">KHUYẾN MÃI NẠP</h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Tài khoản</label>
                            <md-input v-model="user.username" type="text"></md-input>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>XU WEB</label>
                            <md-select v-model="user.xuwebkhuyenmai" name="xuwebkhuyenmai" id="xuwebkhuyenmai">
                                <md-option value="10">10%</md-option>
                                <md-option value="20">20%</md-option>
                                <md-option value="30">30%</md-option>
                                <md-option value="50">50%</md-option>
                                <md-option value="100">100%</md-option>
                            </md-select>                        
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>XU WEB</label>
                            <md-select v-model="user.xugamekhuyenmai" name="xugamekhuyenmai" id="xugamekhuyenmai">
                                <md-option value="10">10</md-option>
                                <md-option value="20">20</md-option>
                                <md-option value="30">30</md-option>
                                <md-option value="50">50</md-option>
                                <md-option value="100">100</md-option>
                                <md-option value="200">200</md-option>
                                <md-option value="300">300</md-option>
                            </md-select>                        
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button @click="formKhuyenMai()" class="md-raised md-success">Khuyến Mãi</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </form>
</template>
<script>
export default {
    name: "khuyen-mai-form",
    props: {
        dataBackgroundColor: {
            type: String,
            default: "",
        },
    },
    async mounted() {
        this.$eventBus.$on('update:khuyenmaiform', this.hendoMGD1)
    },
    destroyed() {
        console.log('destroyed')
        // Stop listening the event hello with handler
        this.$eventBus.$off('update:khuyenmaiform', this.hendoMGD1);
    },
    data() {
        return {
            typePay: null,
            user: {
                username: "",
                xuwebkhuyenmai: "",
                xugamekhuyenmai: "ATM",
                id: 0
            },

            type: ['', 'info', 'success', 'warning', 'danger'],
            defaultPortWeb: 6001,
        };
    },
    methods: {
        hendoMGD1(km_form) {
            console.log(km_form);
            // this.user.username = km_form;
            // this.user.id=km_form.id;
        },
        hendoMGD2() {
            console.log(2);
            // this.user.username = km_form;
            // this.user.id=km_form.id;
        },
      
        validateForm() {
            if(this.user.username == "" || this.user.transaction == "")
                return false;
            return true;
        },
        async formSubmit() {
            if(!this.validateForm()) {
                this.notifyVue('top','center', 4, 'Chưa đủ thông tin')
            } else {
                await this.$ServerWebService.login(this.user, 6001)
                .then( async (res) => {
                    if(!res.data.token) {
                        this.notifyVue('top','center', 4, res.data.message)
                    } else {
                        this.notifyVue('top','center', 2, res.data.message)
                        localStorage.token  = res.data.token;
                        localStorage.username = this.user.Account;
                        localStorage.defaultPortWeb = this.adminlist.find(i => i.Account == this.user.Account).port
                    
                        await this.$ServerWebService.loadingServerList()
                        .then((r) => {
                            this.$eventBus.$emit(`update:serverlist`, r.data);
                        })
                        .catch((e) => {
                        })
                    }
                })
                .catch((eer) => {
                        this.notifyVue('top','center', 4, eer.response.data.message)
                })
            }
        },
        notifyVue (verticalAlign, horizontalAlign, color, msg) {
            this.$notify(
                {
                message: msg,
                icon: 'add_alert',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: this.type[color]
                })
        }
    }
};
</script>
<style>

</style>
