<template>
  <div>
    <form>
      <md-card>
        <md-card-header :data-background-color="dataBackgroundColor">
          <h4 class="title">GM TOOL</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">


            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Chọn máy chủ</label>

                <md-select @md-selected="onProvinceItemSelected" v-model="gmtoolform.serverCode" name="gmtoolformserver_id"
                  id="gmtoolformserver_id">
                  <md-option v-for="(item, idx) in serverList" v-bind:key="idx" :value="item.ServerID">{{ item.ServerName
                  }}</md-option>
                </md-select>

              </md-field>
            </div>


            <div class="md-layout-item md-small-size-100 md-size-55">
              <md-field>
                <label>Loại hỗ trợ</label>

                <md-select @md-selected="onProvinceSupportSelected" v-model="supportSelectedType"
                  name="supportSelectedType" id="supportSelectedType">
                  <md-option v-for="(item, idx) in supportType" v-bind:key="idx" :value="item.type">
                    {{ item.name }}
                  </md-option>
                </md-select>

              </md-field>
            </div>

            <div v-if="supportSelected.roleId == true" class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>{{ supportSelected.roleId_NameFiled }}</label>
                <md-input v-model="gmtoolform.roleId"></md-input>
              </md-field>
            </div>

            <div v-if="supportSelected.packetnum == true" class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>{{ supportSelected.packetnum_NameFiled }}</label>
                <md-input v-model="gmtoolform.packetnum"></md-input>
              </md-field>
            </div>

            <div v-if="supportSelected.title == true" class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>{{ supportSelected.title_NameFiled }}</label>
                <md-input v-model="gmtoolform.title"></md-input>
              </md-field>
            </div>

            <div v-if="supportSelected.content == true" class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>{{ supportSelected.content_NameFiled }}</label>
                <md-input v-model="gmtoolform.content"></md-input>
              </md-field>
            </div>

            <!-- <div class="md-layout-item md-small-size-33 md-size-33">
              <md-checkbox v-model="user.likeshare">Like/Share</md-checkbox>
              <md-checkbox v-model="user.fancung">Fan Cứng</md-checkbox>
            </div> -->
            <div class="md-layout-item md-size-100 text-right">
              <md-button @click="submitGMTool()" class="md-raised md-success">GỬI LỆNH</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>


    <!-- <form>
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">Tặng Xu Like Share / GM TOOL</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Tên tài khoản</label>
              <md-input v-model="user.Account"></md-input>
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-33 md-size-33">
              <md-checkbox v-model="user.likeshare">Like/Share</md-checkbox>
              <md-checkbox v-model="user.fancung">Fan Cứng</md-checkbox>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button @click="submitLikeShare()" class="md-raised md-success">Gửi tặng</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form> -->

  <!-- <form>
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">Phát Quà Video Thất Tuyệt</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div v-for="(item, idx) in userVideo" v-bind:key="idx" class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Tên tài khoản top {{ item.Top }}</label>
              <md-input v-model="item.Account"></md-input>
            </md-field>
          </div>
          
          <div class="md-layout-item md-size-100 text-right">
            <md-button @click="submitVideoTop()" class="md-raised md-success">Gửi tặng</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form> -->
  </div>
</template>
<script>
export default {
  name: "edit-profile-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userVideo: [
        {
          Account: "",
          Top: 1
        },
        {
          Account: "",
          Top: 2
        },
        {
          Account: "",
          Top: 3
        },
        {
          Account: "",
          Top: 4
        },
        {
          Account: "",
          Top: 5
        }
      ],
      user: {
        Account1: "",
        Account2: "",
        Account3: "",
        Account4: "",
        Account5: "",
      },
      gmtoolform: {
        serverCode: 0,
        userId: 0,
        roleId: 0,
        packetnum: 0,
        portGame: 0
      },
      type: ['', 'info', 'success', 'warning', 'danger'],
      disabled: null,
      serverList: [],
      supportType: [
        // {
        //   role: 1,
        //   name: 'Xóa Nội Đan',
        //   type: 'remove_noi_dan'
        // },
        // {
        //   role: 1,
        //   name: 'Reset Fan cứng tuần',
        //   type: 'reset_fancung'
        // },
        // {
        //   role: 1,
        //   name: 'Reset Uy Danh',
        //   type: 'reset_uydanh'
        // },
        // {
        //   role: 2,
        //   name: 'Thêm Acc Build Sẵn',
        //   type: 'add_accbuilded'
        // },
        // {
        //   role: 1,
        //   name: 'Phát Quà CTC - Ngạo Thế',
        //   type: 'phatquactc',
        //   userId: 997,
        //   roleId: false,
        //   roleId_NameFiled: '',
        //   packetnum: false,
        //   packetnum_NameFiled: '',
        //   title: false,
        //   title_NameFiled: '',
        //   content: false,
        //   content_NameFiled: '',
        // },
        {
          role: 1,
          name: 'Reset Uy Danh - Vũ Động',
          type: 'resetUyDanhVDGH',
          userId: 16,
          roleId: false,
          roleId_NameFiled: '',
          packetnum: false,
          packetnum_NameFiled: '',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Cài đặt bang đánh tương dương',
          type: 'setHP_XND1',
          userId: 6,
          roleId: true,
          roleId_NameFiled: 'ID Bang',
          packetnum: true,
          packetnum_NameFiled: 'Vị trí(ĐHT-1401, BLH-1402, GTT-1403, VLT-1404)',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Cài đặt bang đánh lâm an',
          type: 'setHP_XND2',
          userId: 7,
          roleId: true,
          roleId_NameFiled: 'ID Bang',
          packetnum: true,
          packetnum_NameFiled: 'Vị trí(ĐHT-1401, BLH-1402, GTT-1403, VLT-1404)',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Thêm điểm cho bang',
          type: 'setHP_XND3',
          userId: 8,
          roleId: true,
          roleId_NameFiled: 'ID Bang',
          packetnum: true,
          packetnum_NameFiled: 'Số điểm',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        // {
        //   role: 1,
        //   name: 'Xóa nội đan',
        //   type: 'setHP_XND',
        //   userId: 1,
        //   roleId: true,
        //   roleId_NameFiled: 'ID Người chơi',
        //   packetnum: false,
        //   packetnum_NameFiled: '',
        //   title: false,
        //   title_NameFiled: '',
        //   content: false,
        //   content_NameFiled: '',
        // },
        {
          role: 1,
          name: 'Mở vũ động giang hồ',
          type: 'setHP_VDGH',
          userId: 119,
          roleId: false,
          roleId_NameFiled: '',
          packetnum: false,
          packetnum_NameFiled: '',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Mở vận tiêu (Nếu vận tiêu không hoạt động)',
          type: 'setHP_VT',
          userId: 121,
          roleId: false,
          roleId_NameFiled: '',
          packetnum: false,
          packetnum_NameFiled: '',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Đặt lại máu BOSS (Thanh Long, Tắc Thiên, Long Tộc)',
          type: 'setHP_BOSS',
          userId: 201,
          roleId: true,
          roleId_NameFiled: 'Số cây máu',
          packetnum: false,
          packetnum_NameFiled: '',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Cài lại số lần tầng lăng',
          type: 'reset_TL',
          userId: 202,
          roleId: true,
          roleId_NameFiled: 'ID Người chơi',
          packetnum: true,
          packetnum_NameFiled: 'Số lần mở',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Đặt thời gian tầng lăng cho nhân vật',
          type: 'reset_TLT',
          userId: 203,
          roleId: true,
          roleId_NameFiled: 'ID Người chơi',
          packetnum: true,
          packetnum_NameFiled: 'Số giây',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        // {
        //   role: 1,
        //   name: 'Reset Cửa hàng Nguyệt Ảnh cho nhân vật',
        //   type: 'reset_NA',
        //   userId: 204,
        //   roleId: true,
        //   roleId_NameFiled: 'ID Người chơi',
        //   packetnum: false,
        //   packetnum_NameFiled: '',
        //   title: false,
        //   title_NameFiled: '',
        //   content: false,
        //   content_NameFiled: '',
        // },
        {
          role: 1,
          name: 'Thông báo toàn SERVER',
          type: 'notify_all',
          userId: 205,
          roleId: false,
          roleId_NameFiled: '',
          packetnum: false,
          packetnum_NameFiled: '',
          title: true,
          title_NameFiled: 'Tiêu đề thư',
          content: true,
          content_NameFiled: 'Nội dung thư',
        },
        {
          role: 1,
          name: 'Tạo tin mới',
          type: 'reset_NAx1',
          userId: 206,
          roleId: true,
          roleId_NameFiled: 'Thời gian hiển thị (Phút)',
          title: true,
          title_NameFiled: 'Tiêu đề tin',
          content: true,
          content_NameFiled: 'nội dung tin',
        },
        // {
        //   role: 1,
        //   name: 'Xóa toàn bộ boss',
        //   type: 'reset_NAx2',
        //   userId: 207,
        //   roleId: false,
        //   roleId_NameFiled: 'Thời gian hiển thị (Phút)',
        //   title: false,
        //   title_NameFiled: 'Tiêu đề tin',
        //   content: false,
        //   content_NameFiled: 'nội dung tin',
        // },
        // {
        //   role: 1,
        //   name: 'Tạo mới lại bosss',
        //   type: 'reset_NAx3',
        //   userId: 208,
        //   roleId: true,
        //   roleId_NameFiled: 'Thanh Long (1) | Nữ Đế (2) | Long TỘc (3)',
        //   title: true,
        //   title_NameFiled: 'Tiêu đề tin',
        //   content: true,
        //   content_NameFiled: 'nội dung tin',
        // },
        {
          role: 1,
          name: 'Đặt lại máu boss LSV(Thiên Sơn)',
          type: 'reset_NAx3xx',
          userId: 199,
          roleId: false,
          roleId_NameFiled: '',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        // {
        //   role: 1,
        //   name: 'Reset nhận quà 30/4 (Thất tuyệt)',
        //   type: 'reset_NAx3zzz',
        //   userId: 209,
        //   roleId: true,
        //   roleId_NameFiled: 'ID Nhân vật',
        //   title: false,
        //   title_NameFiled: '',
        //   content: false,
        //   content_NameFiled: '',
        // },
        // {
        //   role: 1,
        //   name: 'Reset mở túi xu (Thiên Sơn)',
        //   type: 'reset_NAx3zzxxxz',
        //   userId: 220,
        //   roleId: false,
        //   roleId_NameFiled: 'ID Nhân vật',
        //   title: false,
        //   title_NameFiled: '',
        //   content: false,
        //   content_NameFiled: '',
        // },
        {
          role: 1,
          name: 'Phát Top Bang và Vũ Động (Thiên Sơn)',
          type: 'reset_Nhan_00',
          userId: 200,
          roleId: false,
          roleId_NameFiled: 'ID Nhân vật',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Đặt người diệt boss (Không Lạm Dụng)',
          type: 'reset_Nhan_01',
          userId: 209,
          roleId: true,
          roleId_NameFiled: 'ID Nhân vật',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Di chuyển Player vào khu an toàn & tắt auto train',
          type: 'move_p_train01',
          userId: 222,
          roleId: true,
          roleId_NameFiled: 'ID Nhân vật',
          title: false,
          title_NameFiled: '',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Thông báo hệ thống',
          type: 'move_p_train012',
          userId: 304,
          roleId: false,
          roleId_NameFiled: 'ID Nhân vật',
          title: false,
          title_NameFiled: '',
          content: true,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Cấm máy 24 giờ',
          type: 'band_p_24h',
          userId: 999,
          roleId: true,
          roleId_NameFiled: 'ID Nhân vật',
          packetnum: true,
          packetnum_NameFiled: 'Band mấy tiếng?',
          title: false,
          title_NameFiled: 'Band mấy tiếng?',
          content: false,
          content_NameFiled: '',
        },
        {
          role: 1,
          name: 'Khóa mõm',
          type: 'khoa_mon',
          userId: 305,
          roleId: true,
          roleId_NameFiled: 'ID Nhân vật',
          packetnum: true,
          packetnum_NameFiled: '1 khóa - 2 mở khóa',
          title: false,
          title_NameFiled: 'Band mấy tiếng?',
          content: false,
          content_NameFiled: '',
        },

      ],
      supportSelected: {},
      supportSelectedType: "",
    };
  },

  async mounted() {
    await this.$ServerWebService.loadServerGame()
      .then(async (res) => {

        // res.push({ServerID: 10103, ServerName: "Zone - Thiên Sơn"})

        // console.log(res);

        this.serverList=[];
        

        this.serverList = res;


        
      })
      .catch((eer) => {
        this.$notify(
          {
            message: eer.response.data.message,
            icon: 'add_alert',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          })
      })
  },

  methods: {
    onProvinceItemSelected() {
      // console.log(this.itemSelected);
    },
    onProvinceSupportSelected() { 
      this.supportSelected = this.supportType.find(i => i.type == this.supportSelectedType) || {};
      this.gmtoolform.userId = this.supportSelected.userId;
    },
    notifyVue(verticalAlign, horizontalAlign, color, msg) {
      this.$notify(
        {
          message: msg,
          icon: 'add_alert',
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: this.type[color]
        })
    },
    async submitLikeShare() {
      if (this.user.Account == "" || (!this.user.likeshare && !this.user.fancung)) {
        this.notifyVue('top', 'center', 4, "Chưa nhập đầy đủ thông tin")
      } else {
        await this.$ServerWebService.xulikeshare(this.user)
          .then(r => {
            console.log(r);
            this.notifyVue('top', 'center', 2, r.data.message)
            // this.users.find(i => i.id == id_KM.id).khuyenmai = `${this.user.xuwebkhuyenmai}% và ${this.user.xugamekhuyenmai}M`;
          })
          .catch(e => {
            this.notifyVue('top', 'center', 4, e.response.data.message)
          })
      }
    },
    async submitVideoTop() {
      let status = 0;
      for(let i=0; i < this.userVideo.length; i++) {
        if(this.userVideo[i].Account != "") {
          status=1;
          break;
        }
      }
      if (status == 0) {
        this.notifyVue('top', 'center', 4, 'Thiếu dữ liệu')
      } else {
        await this.$ServerWebService.submitPhatTOPVideo({userVideo: this.userVideo, port: localStorage.portGame})
          .then(r => {
            this.notifyVue('top', 'center', 2, r.message)
            // this.users.find(i => i.id == id_KM.id).khuyenmai = `${this.user.xuwebkhuyenmai}% và ${this.user.xugamekhuyenmai}M`;
          })
          .catch(e => {
            this.notifyVue('top', 'center', 4, e.response.message)
          })
      }
    },
    async submitGMTool() {
      
      if (this.gmtoolform.userId == 0 || this.gmtoolform.serverCode == 0) {
        this.notifyVue('top', 'center', 4, 'Thiếu dữ liệu')
      } else {
        this.gmtoolform.portGame = localStorage.portGame;
        console.log('call-api')
        await this.$ServerWebService.submitGMTOOL(this.gmtoolform)
          .then(r => {
            console.log(r);
            if (r.code == 1000) {
              this.notifyVue('top', 'center', 2, r.message)
            } else {
              this.notifyVue('top', 'center', 4, r.msg)
            }
            
            // this.users.find(i => i.id == id_KM.id).khuyenmai = `${this.user.xuwebkhuyenmai}% và ${this.user.xugamekhuyenmai}M`;
          })
          .catch(e => {
            this.notifyVue('top', 'center', 4, e.response.message)
          })
      }
    }
  }
};
</script>
<style></style>
