import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Login from "@/pages/Login.vue";
import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import LamQuaSuKien from "@/pages/LamQuaSuKien.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import giftcode from "@/pages/giftcode.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import NhanManager from "@/pages/NhanManager.vue";

// const adminlist= this.$admin_list;

function loggedInRedirectHomePage(to, from, next) {

  if(localStorage.token) {
    next('/dashboard');
  } else {
      next();
  }
  
}

function isLoggedIn(to, from, next) {

  if(localStorage.token) {
    next();
  } else {
      next('/loginvuejs');
  }
  
  
}

const routes = [
  {
    path: "",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "redirect",
        name: "redirect",
        component: Dashboard,
      },
      {
        path: "dashboard",
        name: "Tổng Quan",
        component: Dashboard,
        beforeEnter: isLoggedIn
      },
      {
        path: "loginvuejs",
        name: "Login",
        component: Login,
        beforeEnter: loggedInRedirectHomePage
      },
      {
        path: "user",
        name: "User Profile",
        component: UserProfile,
        beforeEnter: isLoggedIn
      },
      {
        path: "nhanmanager",
        name: "Nhân Quản Lý",
        component: NhanManager,
        beforeEnter: isLoggedIn
      },
      {
        path: "table",
        name: "Table List",
        component: TableList,
        beforeEnter: isLoggedIn
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
        beforeEnter: isLoggedIn
      },
      {
        path: "lam-qua-su-kien",
        name: "LamQuaSuKien",
        component: LamQuaSuKien,
        beforeEnter: isLoggedIn
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },

      {
        path: "giftcode",
        name: "giftcode",
        meta: {
          hideFooter: true,
        },
        component: giftcode,
      },

      
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: UpgradeToPRO,
      },
    ],
  },
];

export default routes;
