<template>
  <div class="content">
    <div class="md-layout">

      <div class="md-layout-item md-medium-size-100 md-size-66">
        <duyet-nap-form data-background-color="green"> </duyet-nap-form>
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-50 md-size-50"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Lịch sử nạp ATM</h4>
            <p class="category" style="color: #da3131; font-weight: bold;">{{ msg_load }}</p>

          </md-card-header>
          <md-card-content>
            <simple-table table-header-color="green"></simple-table>
          </md-card-content>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-50 md-size-50"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Lịch sử nạp MOMO</h4>
            <p class="category" style="color: #da3131; font-weight: bold;">{{ msg_load }}</p>

          </md-card-header>
          <md-card-content>
            <momo-table table-header-color="green"></momo-table>
          </md-card-content>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card class="md-card-plain">
          <md-card-header data-background-color="green">
            <h4 class="title">Lịch sử nạp xu</h4>
            <p class="category">Lịch sử nạp xu</p>
          </md-card-header>
          <md-card-content>
            <ordered-table></ordered-table>
          </md-card-content>
        </md-card>
      </div>
    </div>


   

  </div>
</template>

<script>
import { SimpleTable, OrderedTable, MomoTable } from "@/components";
import { DuyetNapForm } from "@/pages";


export default {
  components: {
    OrderedTable,
    SimpleTable,
    MomoTable,
    DuyetNapForm,
  },
  data() {
    return {
      msg_load:'Đang tải dữ liệu ...'
    }
  },
  async mounted() {
        this.$eventBus.$on('update:atm', this.hendoMGD3)
  },
  methods: {
    hendoMGD3() {
            this.msg_load = 'Đã tải dữ liệu xong !';
        },
  }
};
</script>

<style>
.md-list-item-text {
  justify-content: center;
}
</style>
