<template>
  <div id="map">
    <div style="padding: 20px;">
      <div style="display: block; text-align: center;">
        <h1 style="color: red;
    font-weight: bold;">Đang Sửa:{{ nameServerEdit }}</h1>
       </div>
      <div style="font-weight: bold;">
      SỬA NỘI DUNG SERVER <span style="color:red; font-weight: bold;">{{ duplicateSection == true ? 'Lỗi nghiêm trọng: trùng key' : '' }}</span>
    </div>
      <textarea v-on:input="checkStatusContent()" name="content" id="content" cols="120" rows="15" v-model="content"></textarea>
      <div class="md-layout-item md-size-100 text-center">
          <md-button @click="saveContent()" class="md-raised md-success">Lưu</md-button>
      </div>

      <div>
        <h2>Tài liệu</h2>
        <span style="color: blue;"><b>BossDanhTuongMod</b></span>
        
          <ul>

            <md-field>
        <label>Tướng Đang Chọn</label>
        <md-select v-model="szDanhTuongDangChon" @md-selected="szDanhTuongChange" name="szDanhTuongDangChon" id="szDanhTuongDangChon">
            <md-option v-for="(item, idx) in listDanhTuong" v-bind:key="idx" :value="item.id">{{item.name}}</md-option>
        </md-select>
    </md-field>
                <h2>Tướng đang chọn:_ <span style="color:red">{{ szDanhTuongDangChon == 0 ? 'Chưa chọn' : listDanhTuong.find(i => i.id == szDanhTuongDangChon).name}}</span>> <span style="color:red">{{szDanhTuongDangChon}}</span></h2>

            <li><b>bTrangThai</b>: giá trị số <b>0</b> là tắt sự kiện event, giá trị <b>1</b> là bật sự kiện event</li>
            <li><b>intRevHP</b> : là số cây máu của boss</li>
            <li><b>nBossID</b> : là ID Boss mục tiêu giá trị là 1 con số theo bảng bên dưới !</li>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tên Boss</th>
                  <th>ID</th>
                  <th>Tên Boss</th>
                  <th>ID</th>
                  <th>Tên Boss</th>
                  <th>ID</th>
                  <th>Tên Boss</th>
                  <th>ID</th>
                  <th>Tên Boss</th>
                  <th>ID</th>
                  <th>Tên Boss</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ngao Bái</td>
                  <td><b>2</b></td>
                  <td><b>Viên Thừa Chí</b></td>
                  <td>3</td>
                  <td>Hàn Thế Trung</td>
                  <td><b>4</b></td>
                  <td><b>Triết Biệt</b></td>
                  <td>5</td>
                  <td>Cao Trường Cung</td>
                  <td><b>6</b></td>
                  <td><b>Tần Lương Ngọc</b></td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Kinh Kha</td>
                  <td><b>8</b></td>
                  <td><b>Ngu Cơ</b></td>
                  <td>9</td>
                  <td>Nhạc Phi</td>
                  <td><b>10</b></td>
                  <td><b>Lý Quảng</b></td>
                  <td>11</td>
                  <td>Triệu Vân</td>
                  <td><b>12</b></td>
                  <td><b>Lý Nguyên Bá</b></td>
                </tr>
                
              </tbody>
            </table>

            <li><b>nDoTrau</b> : Độ trâu của boss (lấy chung nếu không cài đặt server chi tiết)</li>
            <li><b>nDoTrau_</b> : Độ trâu của boss (lấy theo ID Server sau dấu _ <b>ví dụ: DoTrau_10112=</b> )</li>
            <li><b>QUATIEUDIET</b> : là quà tiêu diệt định dạng Item...</li>
            <li><b>QUABANGTOP1,2,3,4</b> : là quà top dạng Item...</li>
          </ul>
          <span style="color: blue;"><b>EventThiDauMonPhai</b></span>

          <ul>
    <li><b>PhaiThamGia</b>: giá trị số <b></b> là phái được cài đặt để thi đấu</li>
    <md-field>
        <label>Môn Phái</label>
        <md-select v-model="szPhaiDangChon" @md-selected="szPhaiDangChonChange" name="szPhaiDangChon" id="szPhaiDangChon">
            <md-option v-for="(item, idx) in listMonPhai" v-bind:key="idx" :value="item.id">{{item.name}}</md-option>
        </md-select>
    </md-field>
                <h2>Phái đang chọn:_ <span style="color:red">{{ szPhaiDangChon == 0 ? 'Chưa chọn' : listMonPhai.find(i => i.id == szPhaiDangChon).name}}</span>> <span style="color:red">{{szPhaiDangChon}}</span></h2>

  </ul>
          
      </div>
<div>==============================================================================================>>>>>>>>>>></div>
      <div style="font-weight: bold;">
      NỘI DUNG SERVER (Sửa lần gần nhất)
    </div>
      <div>
        <textarea name="content" id="content" cols="120" rows="3">{{ contentold }}</textarea>
      </div>
    </div>



    <!-- <div>Quản Lý SERVER</div>
    <div class="containermod">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-10 md-size-10">
          <md-field>
            <label>Index</label>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-10 md-size-10">
          <md-field>
            <label>ServerId</label>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-30 md-size-30">
          <md-field>
            <label>Tên Máy Chủ</label>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-10 md-size-10">
          <md-field>
            <label>ShowType</label>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-15 md-size-15">
          <md-field>
            <label>Trạng Thái</label>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-15 md-size-15">
          <md-field>
            <label>Xóa</label>
          </md-field>
        </div>
    </div> -->
    <!-- <div v-for="(item, idx) in dataX" :key="idx">
      
      <div class="md-layout">
        <div class="md-layout-item md-small-size-10 md-size-10">
          <md-field>
            <md-input v-model="item.Index"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-10 md-size-10">
          <md-field>
            <md-input v-model="item.ServerId"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-30 md-size-30">
          <md-field>
            <md-input v-model="item.ServerName"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-10 md-size-10">
          <md-field>
            <md-input disabled v-model="item.ShowType"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-15 md-size-15">
          <md-field>
              <select v-model="item.Type" name="type" id="type">
                  <option value="1">Bảo Trì</option>
                  <option value="2">Bật</option>
                  <option value="3">Bật (Mới)</option>
              </select>                        
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-15 md-size-15">
          <span @click="oddIdex(idx)" style="margin-right: 30px;font-weight: bold; color: red; cursor: pointer;">(-)</span>
        </div>
       
      </div>
      <span @click="addNewZ(idx)" style="margin-left: 30px;font-weight: bold; color: #34cc34; cursor: pointer;">(+)</span>
      
    </div> -->
    <!-- <div class="md-ripple">
      <button style="    padding: 10px 30px;
    border: none;
    background: #3eab1e;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;">Lưu</button>
    </div> -->

    <!-- </div> -->

    
  </div>
</template>
<style>
.containermod {
  padding: 30px;
  padding-bottom: 150px;
}
table {
  margin: 0 auto;
}

/* Default Table Style */
table {
  color: #333;
  background: white;
  border: 1px solid grey;
  font-size: 12pt;
  border-collapse: collapse;
}
table thead th,
table tfoot th {
  color: #fff;
  background: #3eab1e;
}
table caption {
  padding:.5em;
}
table th,
table td {
  padding: .5em;
  border: 1px solid lightgrey;
}
</style>
<script>
import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader(API_KEY);
export default {
  // $pathName = $req->path();
  //       $linkadmin = str_replace("6001", Session::get('manager_port'),$this->hostLive);
  //       $response = Http::withToken(Session::get('token'))->get($linkadmin.'api/auth/mauvathuongserver');
  //       $jArr = json_decode($response->body(), true);
  //       $serverName = $jArr["data"];
  //       $serverName2 = $jArr["data2"];
  //       // $serverName="";
  //       return view('admin.maubossvathuong', compact('pathName', 'serverName', 'serverName2'));
  data() {
    return {
      content:'',
      contentold: '',
      duplicateSection: false,
      dataX: [
        { Index: '1', ServerId: '10001', ServerName: 'S1-Quan Hiep', ShowType: '3', Type: '2' },
        { Index: '2', ServerId: '10002', ServerName: 'SV-Admin Pro No3', ShowType: '3', Type: '1' },
        { Index: '3', ServerId: '10003', ServerName: 'S1~S4 Cao Thu Thien Ha', ShowType: '3', Type: '2' },
        { Index: '4', ServerId: '10004', ServerName: 'S4-ThienHa', ShowType: '3', Type: '1' }
      ],
      nameServerEdit: "XXXXX",
      listMonPhai:[
                {id:1, name: "Thiên Vương"},
                {id:2, name: "Nga Mi"},
                {id:3, name: "Đào Hoa"},
                {id:4, name: "Tiêu Dao"},
                {id:5, name: "Võ Đang"},
                {id:6, name: "Thiên Nhẫn"},
                {id:7, name: "Thiếu Lâm"},
                {id:8, name: "Thúy Yên"},
                {id:9, name: "Đường Môn"},
                {id:10, name: "Côn Lôn"},
                {id:11, name: "Cái Bang"},
                {id:12, name: "Ngũ Độc"},
                {id:13, name: "Tàng Kiếm"},
                {id:14, name: "Trường Ca"},
                {id:15, name: "Thiên Sơn"},
                {id:16, name: "Bá Đao"},
                {id:17, name: "Hoa Sơn"},
                {id:18, name: "Minh Giáo"},
                {id:19, name: "Đoàn Thị"},
                {id:20, name: "Vạn Hoa"},
                {id:21, name: "Dương Môn"},
                {id:22, name: "Long Tước"},
                {id:23, name: "Thần Cơ"},
                {id:24, name: "Huyền Thủy"},
                {id:25, name: "Xung Tiêu"},
                {id:26, name: "Võ Đang Thức Tỉnh"},
                {id:27, name: "Long Uy"},
                {id:28, name: "Nga Mi Thức Tỉnh"},
                {id:29, name: "Tiêu Dao Thức Tỉnh"},
                {id:30, name: "Hội Mộng"},
                {id:31, name: "Thiên Nhẫn Thức Tỉnh"},
                {id:32, name: "Tàng Kiếm Thức Tỉnh"},
                {id:33, name: "Thiên Sơn Thức Tỉnh"},
                {id:34, name: "Thiên Tâm"},
            ],
      szPhaiDangChon:0,
      listDanhTuong: [
      {id: 1, name: "Ngao Bái"},
      {id: 2, name: "Viên Thừa Chí"},
      {id: 3, name: "Hàn Thế Trung"},
      {id: 4, name: "Triết Biệt"},
      {id: 5, name: "Cao Trường Cung"},
      {id: 6, name: "Tần Lương Ngọc"},
      {id: 7, name: "Kinh Kha"},
      {id: 8, name: "Ngu Cơ"},
      {id: 9, name: "Nhạc Phi"},
      {id: 10, name: "Lý Quảng"},
      {id: 11, name: "Triệu Vân"},
      {id: 12, name: "Lý Nguyên Bá"}
      ],
      szDanhTuongDangChon: 0
    }
  },
  async mounted() {
    this.nameServerEdit = localStorage.serverName;
    await this.$ServerWebService.loadServerConfigHP()
      .then(async (res) => {
        this.content = res.data;
        this.contentold = res.data2;
        this.layGiaTriPhaiThamGia(res.data);
        this.checkStatusContent();
      })
      .catch((eer) => {
        this.$notify(
          {
            message: eer.response.data.message,
            icon: 'add_alert',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          })
      })
  },
  methods: {
    szPhaiDangChonChange() {
      this.thayDoiGiaTri(this.content, this.szPhaiDangChon, "PhaiThamGia")
    },
    szDanhTuongChange() {
      this.thayDoiGiaTri(this.content, this.szDanhTuongDangChon, "nBossID")
    },
    layGiaTriPhaiThamGia(chuoi) {
      this.szPhaiDangChon=0;
      const dongChuoi = chuoi.split('\n');
      for (const dong of dongChuoi) {
        if (dong.includes("PhaiThamGia=")) {
          const viTriDauBang = dong.indexOf("=");
          const ketQua = dong.substring(viTriDauBang + 1);
          this.szPhaiDangChon= parseInt(ketQua.trim()); // Loại bỏ khoảng trắng thừa nếu có
        }

        if (dong.includes("nBossID=")) {
          const vitribot1 = dong.indexOf("=");
          const ketQua1 = dong.substring(vitribot1 + 1);
          this.szDanhTuongDangChon= parseInt(ketQua1.trim()); // Loại bỏ khoảng trắng thừa nếu có
        }
      }
    },

    thayDoiGiaTri(chuoi, giaTriMoi, PhaiThamGia) {
      // Sử dụng biểu thức chính quy để tìm và thay thế dòng
      const bieuThucChinhQuy = new RegExp(PhaiThamGia + "=([^\\n]*)", "g");
      const chuoiThayThe = chuoi.replace(bieuThucChinhQuy, PhaiThamGia + "=" + giaTriMoi);
      this.content = chuoiThayThe;
    },

    convertObjectToText(objects) {
        const keys = Object.keys(objects[0]);
        const keyString = keys.join('\t');
        const objectStrings = objects.map(obj => {
          return keys.map(key => obj[key]).join('\t');
        });
        const outputString = [keyString, ...objectStrings].join('\n');
        return outputString;
    },
    convertTextToObject(inputString) {
      const lines = inputString.split('\n');

      // Tách phần tử đầu tiên trong mảng làm key
      const key = lines[0].split('\t');

      // Tạo mảng các đối tượng từ các dòng còn lại trong chuỗi
      const objects = lines.slice(1).map(line => {
        const values = line.split('\t');
        const obj = {};
        key.forEach((k, i) => obj[k] = values[i]);
        return obj;
      });
      return objects;
    },
    addNewZ(idx) {
      this.dataX.splice(idx + 1, 0, { Index: '', ServerId: '', ServerName: '', ShowType: '3', Type: '1' });
    },
    oddIdex(idx) {
      this.dataX.splice(idx, 1);
    },
    
    checkStatusContent() {
      const text = this.content;
      const obj = {};
      let currentSection = null;
      let sections = [];
      this.duplicateSection = false; // khởi tạo bStatus với giá trị mặc định là false
      const lines = text.split("\n");

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.startsWith("[") && line.endsWith("]")) {
          currentSection = line.substring(1, line.length - 1);
          if (sections.includes(currentSection)) {
            this.duplicateSection = true; // phát hiện phần tử trùng tên, gán giá trị true cho biến bStatus
            break; // thoát khỏi vòng lặp
          }
          sections.push(currentSection);
          if (!obj[currentSection]) {
            obj[currentSection] = {};
          }
        } else if (line.length > 0 && currentSection !== null) {
          const parts = line.split("=");
          const key = parts[0];
          const value = parts.slice(1).join("=");
          obj[currentSection][key] = value;
        }
      }
    },
    async saveContent() {
      if(this.duplicateSection) {
        alert("có lỗi !");
      } else {
        var req = {content: this.content, port: localStorage.defaultPortWeb};
        await this.$ServerWebService.submitSaveThuongVaMau(req)
        .then(async (r) => {
          this.$notify(
                      {
                          message: r.message,
                          icon: 'add_alert',
                          horizontalAlign: 'center',
                          verticalAlign: 'mid',
                          type: this.$type_noti[2]
                      })
        })
        .catch((eer) => {
          this.$notify(
            {
              message: eer.response.data.message,
              icon: 'add_alert',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            })
        })
      }
      
    }
  }
};
</script>
