<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Phát thưởng TOP</h4>
          </md-card-header>
          <md-card-content>
            <div id="typography">
              <div class="title">
                <h2>Phát thưởng TOP</h2>
              </div>
              <div class="row">
                <div class="tim-typo">
                  <h1>
                    <span class="tim-note">Header 1</span>The Life of Material
                    Dashboard
                  </h1>
                </div>
              </div>

              <div class="title">
                <h2></h2>
              </div>
              <div class="row">
                <div class="tim-typo">
                  <h1>
                    <!-- <span class="tim-note">Header 1</span> -->
                    Cài đặt phần thưởng TOP
                  </h1>
                </div>
              </div>
              <top-luc-chien-table></top-luc-chien-table>
              <!-- <momo-table></momo-table> -->

              <!-- <top-luc-chien-table table-header-color="green"></top-luc-chien-table> -->
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>


<script>

import { TopLucChienTable } from "@/components";

export default {
  components: {
    TopLucChienTable,
    // MomoTable , MomoTable
  },
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
};
</script>
