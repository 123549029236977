<template>
    <div>
        <div class="md-layout-item md-small-size-100 md-size-100">
            
            
            <md-field>
                <label>Đợt Đua</label>
                <md-select v-model="dotdua" @md-selected="changedotdua" name="dotdua" id="dotdua">
                    <md-option v-for="item in totalUsers" v-bind:key="item.ID" :value="item.ID">{{
                        item.Title
                    }}</md-option>
                </md-select>
            </md-field>
            <!-- <h3>Phần Thưởng</h3>  -->
            
            <!-- @click="desClick(item.tranId)" -->
        
        </div>
        <md-card>
          <md-card-header data-background-color="green">
            <div style="display: flex;">
                <h4 style="flex: 10;" class="title">Thưởng Top Lực Chiến</h4>
                <button style="flex: 1;    flex: 1 1 0%;
    border: none;
    border-radius: 15px;
    color: red;
    font-weight: bold;
    cursor: pointer;" @click="save_top_luc_chien()">Lưu</button>
            </div>
          </md-card-header>
          <md-card-content>
            <md-table v-model="totalUsers.find(i => i.ID == dotdua) == undefined ? {} : totalUsers.find(i => i.ID == dotdua).vitri" :table-header-color="tableHeaderColor">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <!-- <md-table-cell md-label="Action">
                        <button class="edit-btn">
                            Sửa
                        </button>
                    </md-table-cell> -->
                    <md-table-cell style="width: 10px;" md-label="Vị Trí từ">{{ item.ViTri_A }}</md-table-cell>
                    <md-table-cell style="width: 10px;" md-label="đến Vị Trí">{{ item.ViTri_B }}</md-table-cell>
                    <md-table-cell style="color: red; font-weight: bold;" md-label="Phần Quà">
                        <md-input class="edit-input" v-model="item.PhanThuong" type="text"></md-input>
                    </md-table-cell>
                </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
        
        
    </div>
</template>
<style>
.edit-input {
    width: 100%;
    padding: 10px;
    border: none;
}
.edit-btn {
    border: navajowhite;
    padding: 5px 10px;
    /* color: green; */
    color: #fff;
    background: #7373ed;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
}
</style>

<script>
export default {
    name: "top-luc-chien-table",
    props: {
        tableHeaderColor: {
            type: String,
            default: "green",
        },
    },
    data() {
        return {
            dotdua: 0,
            selected: [],
            totalUsers: [{}],
            users: [
            ],
            save_lc: {
                id: 0,
                vitri: {}
            }
        };
    },
    async created() {
        // this.$eventBus.$on('update:momo', this.handelmomo)
    },
    methods: {
        async save_top_luc_chien() {
            if(this.dotdua == 0) {
                this.$notify(
                    {
                    message: "Chưa đủ thông tin",
                    icon: 'add_alert',
                    horizontalAlign: 'center',
                    verticalAlign: 'mid',
                    type: this.$type_noti[4]
                    })
            } else {
                this.save_lc.id = this.dotdua;
                this.save_lc.vitri = totalUsers.find(i => i.ID == dotdua).vitri;
                await this.$ServerWebService.savedulieuduatoplc(this.save_lc)
                .then(r => {
                    this.$notify(
                    {
                        message: r.message,
                        icon: 'add_alert',
                        horizontalAlign: 'center',
                        verticalAlign: 'mid',
                        type: this.$type_noti[2]
                    })
                })
                .catch(e => {
                    this.$notify(
                    {
                        message: e.response.data.message,
                        icon: 'add_alert',
                        horizontalAlign: 'center',
                        verticalAlign: 'mid',
                        type: this.$type_noti[4]
                    })
                })
            }
        },
        changedotdua() {
            console.log(this.dotdua);
        //     console.log(this.totalUsers);

        //    this.users = this.totalUsers.find(i => i.ID == dotdua)
        //    console.log(this.users);
        }
        // handelmomo(e) {
        //     this.users = e;
        // },
        // desClick(des) {
        //     console.log(des);
        //     this.$eventBus.$emit('update:magdsai1', des)
        // }
    },
    async mounted() {
        await this.$ServerWebService.dulieuduatop()
            .then(r => {
                this.totalUsers = r.data.settings;
                for (let i = 0; i < this.totalUsers.length; i++) {
                    this.totalUsers[i].vitri={}
                    console.log(this.totalUsers[i]);
                    this.totalUsers[i].vitri = r.data.vitri.filter(j => j.IDTOP == this.totalUsers[i].ID)
                }
            })
            .catch(e => {
            })

    }
};
</script>
