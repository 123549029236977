<template>
    <form>
        <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
                <h4 class="title">Đăng Nhập Hệ Thống</h4>
                <p class="category">Cao Thủ Team</p>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Tài khoản</label>
                            <md-input v-model="user.Account" type="text"></md-input>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>Mật khẩu</label>
                            <md-input v-model="user.Password" type="password"></md-input>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button @click="formSubmit()" class="md-raised md-success">Đăng Nhập</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </form>
</template>
<script>
export default {
    name: "login-form",
    props: {
        dataBackgroundColor: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            user: {
                Account: "",//trantrongnhan1
                Password: "",//022568320
                captcha: "123456"
            },
            adminlistUser: [],
            adminlist: this.$admin_list,

            type: ['', 'info', 'success', 'warning', 'danger'],
            defaultPortWeb: 6001
        };
    },
    methods: {

        validateForm() {
            this.adminlistUser = this.adminlist.map(function (i) {
                return i.Account;
            })
            return this.adminlistUser.includes(this.user.Account);
        },
        async formSubmit() {
            if (!this.validateForm()) {
                this.notifyVue('top', 'center', 4, 'Tài khoản ADMIN không tồn tại trong hệ thống')
            } else {
                await this.$ServerWebService.login(this.user, 6001)
                    .then(async (res) => {
                        if (!res.data.token) {
                            this.notifyVue('top', 'center', 4, res.data.message)
                        } else {
                            this.notifyVue('top', 'center', 2, res.data.message)
                            localStorage.token = res.data.token;
                            localStorage.username = this.user.Account;
                            localStorage.defaultPortWeb = this.adminlist.find(i => i.Account == this.user.Account).port
                            localStorage.bankCode = this.adminlist.find(i => i.Account == this.user.Account).bankCode
                            localStorage.portGame = this.adminlist.find(i => i.Account == this.user.Account).portGame
                            localStorage.nType = this.adminlist.find(i => i.Account == localStorage.username).nType
                            await this.$ServerWebService.loadingServerList()
                            
                                .then((r) => {
                                    this.$eventBus.$emit(`update:serverlist`, r.data);
                                })
                                .catch((e) => {
                                })
                                this.$router.push('/')
                            this.$eventBus.$emit(`update:login`)
                        }
                    })
                    .catch((eer) => {
                        console.log(eer);
                        // this.notifyVue('top', 'center', 4, eer.response.data.message)
                    })
            }
        },
        notifyVue(verticalAlign, horizontalAlign, color, msg) {
            this.$notify(
                {
                    message: msg,
                    icon: 'add_alert',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: this.type[color]
                })
        }
    }
};
</script>
<style></style>
