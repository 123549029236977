<template>
    <form>
        <md-card>
            <md-card-header :data-background-color="dataBackgroundColor">
                <h4 class="title">DUYỆT NẠP SAI</h4>
                <p class="category" >CAO THỦ TEAM</p>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Tài khoản</label>
                            <md-input v-model="user.Account" type="text"></md-input>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-small-size-100 md-size-33">
                        <md-field>
                            <label>Loại Nạp</label>
                            <md-select v-model="user.type" name="type" id="type">
                                <md-option value="ATM">ATM</md-option>
                                <md-option value="MOMO">MOMO</md-option>
                            </md-select>                        
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>Mã Giao Dịch Sai</label>
                            <md-input v-model="user.tranId" type="text"></md-input>
                        </md-field>
                    </div>
                    
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button @click="formSubmit()" class="md-raised md-success">Duyệt Nạp</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </form>
</template>
<script>
export default {
    name: "duyet-nap-form",
    props: {
        dataBackgroundColor: {
            type: String,
            default: "",
        },
    },
    async mounted() {
        this.$eventBus.$on('update:magdsai1', this.hendoMGD1)
        this.$eventBus.$on('update:magdsai2', this.hendoMGD2)

    },
    data() {
        return {
            typePay: null,
            user: {
                Account: "",
                tranId: "",
                type: "ATM",
                captcha: "123456"
            },
            adminlistUser: [],
            adminlist: this.$admin_list,

            type: ['', 'info', 'success', 'warning', 'danger'],
            defaultPortWeb: 6001,
        };
    },
    methods: {
        hendoMGD1(des) {
            this.user.type="MOMO";
            this.user.tranId = des;
        },
        hendoMGD2(des) {
            this.user.type="ATM";
            this.user.tranId = des;
        },
      
        validateForm() {
            if(this.user.username == "" || this.user.transaction == "")
                return false;
            return true;
        },
        async formSubmit() {
            if(!this.validateForm()) {
                this.notifyVue('top','center', 4, 'Chưa đủ thông tin')
            } else {
                await this.$ServerWebService.adminduyetnap(this.user, 6001)
                .then( async (res) => {
                    // if(!res.data.token) {
                        this.notifyVue('top','center', 2, res.data.message)
                    // } else {
                    //     this.notifyVue('top','center', 2, res.data.message)
                    //     localStorage.token  = res.data.token;
                    //     localStorage.username = this.user.Account;
                    //     localStorage.defaultPortWeb = this.adminlist.find(i => i.Account == this.user.Account).port
                    
                    //     await this.$ServerWebService.loadingServerList()
                    //     .then((r) => {
                    //         this.$eventBus.$emit(`update:serverlist`, r.data);
                    //     })
                    //     .catch((e) => {
                    //     })
                    // }
                })
                .catch((eer) => {
                        this.notifyVue('top','center', 4, eer.response.data.message)
                })
            }
        },
        notifyVue (verticalAlign, horizontalAlign, color, msg) {
            this.$notify(
                {
                message: msg,
                icon: 'add_alert',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: this.$type_noti[color]
                })
        }
    }
};
</script>
<style>

</style>
