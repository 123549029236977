// =========================================================
// * Vue Material Dashboard - v1.5.1
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";


// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
  mode: 'history',
});
const EventBus = new Vue()

import ServerWebService from './store/ServerWebService';

Vue.prototype.$Chartist = Chartist;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$ServerWebService = ServerWebService;
Vue.prototype.$type_noti = ['', 'info', 'success', 'warning', 'danger'];

Vue.prototype.$admin_list = [
 
 
  {
      Account: "trantrongnhan1",
      port: 6013,
      portGame: 25679,
      bankCode: 'VCB',
      nType: 1,
  },
  
];



ServerWebService.loadServerGame().then(async (res) => {
        Vue.prototype.$serverList = res;
      })
      .catch((eer) => {
      })

Number.prototype.format = function(n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

// import {LoadingPlugin} from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/css/index.css';
import vmodal from 'vue-js-modal'
Vue.use(vmodal)

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
// Vue.use(LoadingPlugin);


// import Vue from 'vue'


// Vue.use(EventBus);
// export default EventBus

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  data: {
    Chartist: Chartist,
  },
});
